/* eslint-disable indent */
import { useEffect, useState, useContext } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  TextField,
  Alert,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';

import { useAppDispatch, useAppSelector } from 'store/hooks';
import { AuthContext } from 'utils/AuthContext';
import { pharmacyDetailsSchema } from 'utils/schema';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { getPharmacyDetails, savePharmacyDetails } from 'store/slices/userSlice';
import { toast } from 'react-toastify';
import { ROUTE } from 'route/RouteEnums';
import { useNavigate } from 'react-router-dom';
import ReactPhoneInput from 'react-phone-input-material-ui';
import { YES, permissions } from 'utils/constants';
import { Restricted } from 'components/Restricted';

const PharmacyDetails = (props: any) => {
  const [authorizationMsg, setAuthorizationMsg] = useState<boolean>(false);
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    watch,
    reset,
  } = useForm({
    resolver: yupResolver(pharmacyDetailsSchema),
    mode: 'all',
  });
  const isProfileDetailsFetched = useAppSelector((state) => state.user.loaders.isProfileDetailsFetched);
  const { user } = useContext(AuthContext);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.patientId !== undefined || props?.patientId !== undefined) {
      fetchPharmacyDetails(props?.from === 'admin' ? props?.patientId : user?.patientId);
    } else {
      const message = 'Please add patient details first.';
      toast(message, {
        type: 'info',
        icon: true,
      });
      navigate(ROUTE.PATIENT_INFORMATION);
    }
    //eslint-disable-next-line
  }, [dispatch, setValue, user?.patientId]);

  const fetchPharmacyDetails = (patientId: any) => {
    dispatch(getPharmacyDetails(patientId))
      .unwrap()
      .then((response: any) => {
        if (response?.status === 200 && response?.data !== '') {
          Object.keys(response?.data).forEach((key: any) => {
            switch (key) {
              case 'accessMedicationHistory':
                setValue(
                  'accessMedicationHistory',
                  response?.data?.accessMedicationHistory !== null
                    ? response?.data?.accessMedicationHistory
                      ? 'true'
                      : 'false'
                    : '',
                  {
                    shouldValidate: true,
                    shouldDirty: false,
                  }
                );
                if (!response?.data?.accessMedicationHistory && response?.data?.accessMedicationHistory !== null)
                  setAuthorizationMsg(!response?.data?.accessMedicationHistory);
                break;
              case 'phoneNumber':
                if (response?.data[key].length === 10) setValue(key, `1${response?.data[key]}`);
                else setValue(key, response?.data[key]);
                break;
              default:
                setValue(key, response.data[key], { shouldValidate: true, shouldDirty: false });
            }
          });
        }
      });
  };


  const onSubmitForm = (formData: any) => {
    if (isValid && isDirty) {
      const payload = {
        ...formData,
        phoneNumber: formData?.phoneNumber.slice(1),
        primaryPharmacy:'No'
      };
      if (user?.patientId) {
        dispatch(savePharmacyDetails({ patientId: user?.patientId, payload: payload }))
          .unwrap()
          .then((res: any) => {
            if (res.status === 200) {
              toast('Pharmacy details added successfully', {
                type: 'success',
                icon: true,
              });
              reset(formData);
              props?.onComplete();
            }
          });
      } else {
        const message = 'Please add patient details first.';
        toast(message, {
          type: 'info',
          icon: true,
        });
        navigate(ROUTE.PATIENT_INFORMATION);
      }
    } else {
      toast('No changes to update.', {
        type: 'info',
        icon: true
      });
    }
  };

  return (
    <Container component='main' maxWidth='sm'>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box component='form' noValidate sx={{ mt: 1 }} onSubmit={handleSubmit((formData) => onSubmitForm(formData))}>
          <Grid container spacing={2}>
            <Typography sx={{m:'15px 0 0 15px' }}>
            Enter your pharmacy details to ensure seamless medication refills.
            </Typography>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor='pharmacyName'>
                {props?.from !== 'admin' ? 'Pharmacy Name*' : 'Pharmacy Name'}
              </InputLabel>
              <TextField
                required
                fullWidth
                placeholder="Enter your pharmacy's name"
                id='pharmacyName'
                autoComplete='pharmacyName'
                {...register('pharmacyName')}
                error={!!errors.pharmacyName}
                helperText={errors?.pharmacyName?.message}
                disabled={props?.from === 'admin'}
                inputProps={{
                  maxLength: 255,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor='phoneNumber'>
                {props?.from !== 'admin' ? 'Pharmacy’s  Phone number*' : 'Pharmacy’s  Phone number'}
              </InputLabel>
              <ReactPhoneInput
                component={TextField}
                label=''
                onlyCountries={['us']}
                country={'us'}
                masks={{ us: '(...) ...-....' }}
                autoFormat={true}
                disableDropdown={true}
                defaultErrorMessage={'Please enter a Pharmacy’s  phone number'}
                placeholder='+1 (555) 000-0000'
                {...register('phoneNumber')}
                inputProps={{
                  helperText: errors.phoneNumber ? errors.phoneNumber?.message : '',
                  error: !!errors.phoneNumber,
                }}
                countryCodeEditable={false}
                onChange={(e) => {
                  setValue('phoneNumber', e, { shouldValidate: true });
                }}
                value={watch('phoneNumber') ? watch('phoneNumber') : null}
                disabled={props?.from === 'admin'}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel htmlFor='address'>
                Pharmacy&apos;s Address*
              </InputLabel>
              <TextField
                fullWidth
                multiline
                rows={6}
                placeholder="Enter pharmacy's address..."
                id='address'
                autoComplete='address'
                variant='filled'
                {...register('address')}
                error={!!errors.address}
                helperText={errors.address ? errors.address?.message : ''}
                value={watch('address')}
                disabled={ props?.from === 'admin'}
                inputProps={{
                  maxLength: 255,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor='zip-code'>
                {props?.from !== 'admin' ? 'Zip Code*' : 'Zip Code'}
              </InputLabel>
              <TextField
                fullWidth
                id='where-are-you-located?'
                autoComplete='zip-code'
                placeholder='Zip Code'
                {...register('zipCode')}
                error={!!errors.zipCode}
                helperText={errors?.zipCode ? errors?.zipCode?.message : ''}
                value={watch('zipCode')}
                disabled={props?.from === 'admin'}
                inputProps={{
                  maxLength: 5,
                  pattern: '[0-9]{5}'
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel htmlFor='accessMedicationHistory'>
                Do you authorize us to obtain medication history?*
              </InputLabel>
              <FormControl error={!!errors.accessMedicationHistory}>
                <RadioGroup
                  aria-labelledby='demo-form-control-label-placement'
                  name='accessMedicationHistory'
                  id='accessMedicationHistory'
                  value={watch('accessMedicationHistory') ? watch('accessMedicationHistory') : null}
                >
                  <FormControlLabel
                    value='true'
                    control={<Radio />}
                    label='Yes'
                    {...register('accessMedicationHistory')}
                    disabled={props?.from === 'admin'}
                    onClick={props?.from !== 'admin' ? () => setAuthorizationMsg(false) : () => { console.log(); }}
                  />
                  <FormControlLabel
                    value='false'
                    control={<Radio />}
                    label='No'
                    {...register('accessMedicationHistory')}
                    disabled={props?.from === 'admin'}
                    onClick={props?.from !== 'admin' ? () => setAuthorizationMsg(true) : () => { console.log(); }}
                  />
                </RadioGroup>
                {errors.accessMedicationHistory && (
                  <FormHelperText>{errors.accessMedicationHistory?.message}</FormHelperText>
                )}
              </FormControl>
              {authorizationMsg ? (
                <Alert severity='error'>
                  Authorization needed for comprehensive medication history to enhance care. Please reconsider your
                  response.
                </Alert>
              ) : null}
            </Grid>

            <Restricted permission={permissions.EDIT_PROFILE}>
              <Grid item xs={12} display={'flex'} justifyContent={'end'}>
                <LoadingButton
                  loading={isProfileDetailsFetched}
                  variant='contained'
                  type='submit'
                  sx={{ mt: 2, mb: 2 }}
                >
                  Save
                </LoadingButton>
              </Grid>
            </Restricted>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default PharmacyDetails;
