/* eslint-disable max-len */
import { useState, useEffect, useContext } from 'react';
import {
  Grid, Container, Typography, styled, Card, CardProps,
  CardContent, Box, Button,
  TextField,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import 'react-multi-carousel/lib/styles.css';

import { actionRequestForAdmin, getGuardianDetails, getRequestDetails, sendRequest } from 'store/slices/accountManagementSlice';
import { useAppDispatch } from 'store/hooks';
import { Page } from 'components/Page';
import { ROUTE } from 'route/RouteEnums';
import { toast } from 'react-toastify';
import { AuthContext } from 'utils/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';


const StyleCard = styled(Card)<CardProps>(({ theme }) => ({
  boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.12)',
  border: '1px solid #EFF4FF',
  borderRadius: 12,
  background: '#FFF',
  margin: '12px 16px',
  position: 'relative',
  zIndex: 99,
  '& .MuiTypography-root': {
    marginBottom: '8px',
    position: 'relative',
  },
  width: '100%',
  padding:'0px 36px'
}));


const DeactivateAccount = (props:any) => {
  const [disableSubmitButton, setDisableSubmitButton] = useState<boolean>(true);
  const [name, setName] = useState<any>();
  const [emptyName, setEmptyName] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const [patientName, setPatientName] = useState<string>();
  const [dateOfBirth, setDateOfBirth] = useState<string>();  
  const [primaryGuardian, setPrimaryGuardian] = useState<any>();
  const [isConsent1Checked, setIsConsent1Checked] = useState<boolean>(false);
  const [isConsent2Checked, setIsConsent2Checked] = useState<boolean>(false);
  const [isConsent3Checked, setIsConsent3Checked] = useState<boolean>(false);
  const [isConsent4Checked, setIsConsent4Checked] = useState<boolean>(false);
  const [isConsent5Checked, setIsConsent5Checked] = useState<boolean>(false);
  const [isConsent6Checked, setIsConsent6Checked] = useState<boolean>(false);
  const [isConsent7Checked, setIsConsent7Checked] = useState<boolean>(false);
  const [isConsent8Checked, setIsConsent8Checked] = useState<boolean>(false);
  const [reason, setReason] = useState<string>('');
  const [emptyReason, setEmptyReason] = useState<boolean>(false);
  const [allConsentsChecked, setAllConsentsChecked] = useState<boolean>(false);
  const [reasonForRejection, setReasonForRejection]= useState<any>('');  
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [changeSuccessMessage, setChangeSuccessMessage] = useState<boolean>(false);
  const [showRejectionModal, setShowRejectionModal] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const {user} = useContext(AuthContext);
  const {state} = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isConsent1Checked && isConsent2Checked && isConsent3Checked && isConsent4Checked &&
        isConsent5Checked && isConsent6Checked && isConsent7Checked && isConsent8Checked){
      setAllConsentsChecked(true);
    }
  },[isConsent1Checked, isConsent2Checked, isConsent3Checked, isConsent4Checked, isConsent5Checked, isConsent6Checked, isConsent7Checked, isConsent8Checked]);
  
  useEffect(() => {
    const data={patientId:user?.patientId, requestType:'deactivateAccount'};
    dispatch(getGuardianDetails(data))
      .unwrap()
      .then((response:any) => {
        if(response?.status === 200){ 
          setDateOfBirth(response?.data?.dob);
          setPatientName(response?.data?.patientFullName);       
          setPrimaryGuardian(response?.data?.from);
        }
      });

    if(state?.ticketId){
      dispatch(getRequestDetails(state?.ticketId))
        .unwrap()
        .then((response:any) => {
          if(response?.status === 200){
            const details = JSON.parse(response?.data?.requestData);
            setPrimaryGuardian(details?.from);
            setReason(details?.reasonForTransfer);
            setPatientName(details?.patientFullName);
            setDateOfBirth(details?.dob);
            setName(details?.submittedBy);
            setReasonForRejection(details?.reasonForRejection);
          }
        });

    }
  },[user?.patientId, dispatch, state?.ticketId]);

  const handleRelationCheckbox=(e:any)=>{
    if(e.target.checked){
      setDisableSubmitButton(false);
      setIsChecked(true);
    } else {
      setDisableSubmitButton(true);
      setIsChecked(false);
    }
  };

  const handleConsentCheckbox=async(e:any)=>{
    const id = e.target.name;
    
    if(e.target.checked){
      switch(Number(id)){
      case 1: setIsConsent1Checked(true); break;
      case 2: setIsConsent2Checked(true); break;
      case 3: setIsConsent3Checked(true); break;
      case 4: setIsConsent4Checked(true); break;
      case 5: setIsConsent5Checked(true); break;
      case 6: setIsConsent6Checked(true); break;
      case 7: setIsConsent7Checked(true); break;
      case 8: setIsConsent8Checked(true); break;
      }
    }else{
      switch(Number(id)){
      case 1: setIsConsent1Checked(false); break;
      case 2: setIsConsent2Checked(false); break;
      case 3: setIsConsent3Checked(false); break;
      case 4: setIsConsent4Checked(false); break;
      case 5: setIsConsent5Checked(false); break;
      case 6: setIsConsent6Checked(false); break;
      case 7: setIsConsent7Checked(false); break;
      case 8: setIsConsent8Checked(false); break;
      }
      setAllConsentsChecked(false);
    }
  };



  const onSubmitForm=()=>{
    if( reason=== '' || reason === undefined){
      setEmptyReason(true);
    }
    if(name === undefined || name === ''){
      setEmptyName(true);
    }

    if(reason && name){
      const data={
        requestData: JSON.stringify({
          ticketType: 'DEACTIVATE_ACCOUNT',
          reasonForTransfer:reason,
          submittedBy : name,
          patientId: user?.patientId,
          userId: user?.userId,
          from: primaryGuardian,
          patientFullName: patientName,
          dob:dateOfBirth,
        }),
        patientId: user?.patientId,
        userId: user?.userId,
        requestType: 'DEACTIVATE_ACCOUNT',
      };   
      
      dispatch(sendRequest(data))
        .unwrap()
        .then((response:any) => {
          if(response.status === 200){
            if(response?.data?.includes('Old')){
              setChangeSuccessMessage(true);
            }
            setShowSuccessModal(true);
          }
        });
    }
  };

  const handleConfirmation=()=>{
    setShowConfirmationModal(true);
  };

  const handleReject=()=>{
    setShowRejectionModal(true);
  };

  const handleAction=(action:string)=>{
    const data={
      ticketId:state?.ticketId,
      action,
      reasonForRejection
    };
    dispatch(actionRequestForAdmin(data))
      .unwrap()
      .then((response: any) => {
        if (response?.status === 200) {
          if(action === 'Deactivate'){
            setChangeSuccessMessage(true);
            setShowConfirmationModal(true);
          }
        }
      });
  };
  
  return (
    <Page backDir={props?.from === 'admin' ? ROUTE.ADMIN_ACCOUNT_MANAGEMENT_REQUEST : ROUTE.ACCOUNT_MANAGEMENT}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Container component='section'>
            <Grid container>
              <Grid item xs={7} sm={4.5}>            
                <Typography
                  variant='h5'
                  position={'relative'}
                  width={'fit-content'}
                  sx={{ paddingTop: 6, textTransform: 'capitalize' }}
                >
                Deactivate Account
                </Typography>
              </Grid>   
              {state?.ticketId ?
                <Grid item  alignItems={'flex-start'}>
                  <Typography 
                    sx={{background: state?.status === 'Rejected' || state?.status === 'Transfer Failed' ? '#FFFBFA' : state?.status === 'Approved' ? '#F6FEF9' : '#F5F8FF', 
                      color: state?.status === 'Rejected' || state?.status === 'Transfer Failed' ? '#B42318' : state?.status === 'Approved' ? '#027A48' : '#1570EF', 
                      border: state?.status === 'Rejected' || state?.status === 'Transfer Failed' ? '1px solid #B42318' : state?.status === 'Approved' ? '1px solid #027A48' : '1px solid #1570EF', 
                      borderRadius: '12px', padding: '2px 10px 0px 10px', marginTop: {xs:7,sm:8}
                    }}> 
                    {state?.status}
                  </Typography> 
                </Grid>        
                : null }   
            </Grid>

            <Grid container marginTop={4}>
              {reasonForRejection !== undefined && reasonForRejection !=='' ?
                <StyleCard sx={{padding: {xs:'0px', sm:'0px 36px'}}}>
                  <CardContent>
                    <Typography variant='h2'>This Request is Rejected!</Typography>
                    <InputLabel>Reason for Rejection</InputLabel>
                    <Typography sx={{border:'1px solid #D0D5DD', borderRadius:'8px', padding:'10px 14px', background:'#EEF0F6'}}>{reasonForRejection}</Typography>
                  </CardContent>
                </StyleCard> : null}

              <StyleCard sx={{padding: {xs:'0px', sm:'0px 36px'}}}>                
                <CardContent>                                  
                  <Box m='16px 0' pb='24px' borderBottom={'1px solid #EAECF0'}>
                    <Typography variant='h2'>Patient Details</Typography>
                    <Grid container spacing={2} mt='12px'>
                      <Grid item xs={6}>
                        <Typography sx={{ fontSize: '15px' }}> Name: { patientName !== undefined ? patientName?.split(' ')[0]?.charAt(0)?.toUpperCase()+patientName?.split(' ')[0]?.slice(1) +' ' + patientName?.split(' ')[1]?.charAt(0)?.toUpperCase()+ patientName?.split(' ')[1]?.slice(1) : ''} </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography sx={{fontSize:'15px'}}> DOB: {dateOfBirth !== undefined ? dateOfBirth : ''} </Typography>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box m='16px 0' pb='24px' borderBottom={'1px solid #EAECF0'}>
                    <Typography variant='h2'>Primary Guardian Details</Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <InputLabel>First Name</InputLabel>
                        <TextField
                          fullWidth
                          value={primaryGuardian?.firstName}
                          disabled 
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <InputLabel>Last Name</InputLabel>
                        <TextField
                          fullWidth
                          value={primaryGuardian?.lastName}
                          disabled 
                        />
                      </Grid>
                    
                      <Grid item xs={12} sm={6}>
                        <InputLabel>Phone Number</InputLabel>
                        <TextField
                          fullWidth
                          value={primaryGuardian?.phoneNumber}
                          disabled 
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <InputLabel>Email</InputLabel>              
                        <TextField
                          fullWidth
                          value={primaryGuardian?.email}
                          disabled 
                        />
                      </Grid>
                    
                      <Grid item xs={12} sm={6}>
                        <InputLabel>Relationship to the Patient</InputLabel>
                        <TextField
                          fullWidth
                          value={primaryGuardian?.relationShip}
                          disabled 
                        />
                      </Grid>
                    </Grid>
                  </Box>


                  <Box m='16px 0' pb='24px' borderBottom={'1px solid #EAECF0'}>
                    <Typography variant='h2'>Reason to Deactivate</Typography>                      
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <InputLabel htmlFor='reason'>Reason*</InputLabel>
                        <TextField
                          id='reason'
                          fullWidth
                          required
                          multiline  
                          rows={2}   
                          variant='filled'                      
                          placeholder='Briefly explain your reason for deactivating.'
                          onChange={(e:any) => {setReason(e.target.value); setEmptyReason(false);}}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '&.MuiInputBase-root fieldset': {
                                borderColor: emptyReason ? '#d32f2f' : '#D0D5DD',
                              },
                            },
                          }}
                          value={reason}
                          disabled={state?.ticketId}
                        />
                        {emptyReason ? <Typography color={'#d32f2f'} fontSize={'12px'}>Please Enter Reason </Typography> : ''}
                      </Grid>
                    </Grid>
                  </Box>


                  <Box m='16px 0' pb='24px' borderBottom={'1px solid #EAECF0'}>
                    <Typography variant='h2'>Consent and Authorization</Typography>
                    <Typography variant='body1'> Please read the following statements carefully and check each box to confirm </Typography>
                   
                    <>                     
                      <FormControlLabel 
                        sx={{
                          '.MuiFormControlLabel-label':{
                            marginTop :'10px',
                          },
                          background:'#F2F4F7',
                          padding: '10px 12px',
                          border:'1px solid #EEF0F6',
                          borderRadius:'8px',
                          mb:'16px',
                          width:'100%'
                        }}
                        name='1'
                        control={<Checkbox onChange={(e:any)=>handleConsentCheckbox(e)} checked={isConsent1Checked || state?.ticketId}/>}
                        label="I confirm that I am the primary guardian of the patient listed above." /> 

                      <FormControlLabel 
                        sx={{
                          '.MuiFormControlLabel-label':{
                            marginTop :'10px',
                          },
                          background:'#F2F4F7',
                          padding: '10px 12px',
                          border:'1px solid #EEF0F6',
                          borderRadius:'8px',
                          mb:'16px',
                          width:'100%'
                        }}
                        name='2'
                        control={<Checkbox onChange={(e:any)=>handleConsentCheckbox(e)} checked={isConsent2Checked || state?.ticketId}/>}
                        label="I authorize the deactivation of the listed patient’s account on the MindWeal app." /> 


                      <FormControlLabel 
                        sx={{
                          '.MuiFormControlLabel-label':{
                            marginTop :'10px',
                          },
                          background:'#F2F4F7',
                          padding: '10px 12px',
                          border:'1px solid #EEF0F6',
                          borderRadius:'8px',
                          mb:'16px',
                          width:'100%'
                        }}
                        name='3'
                        control={<Checkbox onChange={(e:any)=>handleConsentCheckbox(e)} checked={isConsent3Checked || state?.ticketId}/>}
                        label="I understand that deactivating this account will remove my ability to manage or view the listed patient’s records on the platform." /> 

                      <FormControlLabel 
                        sx={{
                          '.MuiFormControlLabel-label':{
                            marginTop :'10px',
                          },
                          background:'#F2F4F7',
                          padding: '10px 12px',
                          border:'1px solid #EEF0F6',
                          borderRadius:'8px',
                          width:'100%',
                          mb:'16px',
                        }}
                        name='4'
                        control={<Checkbox onChange={(e:any)=>handleConsentCheckbox(e)} checked={isConsent4Checked || state?.ticketId}/>}
                        label="I acknowledge that deactivating the account will stop all notifications and communications through the MindWeal app regarding the listed patient." />
                     
                      <FormControlLabel 
                        sx={{
                          '.MuiFormControlLabel-label':{
                            marginTop :'10px',
                          },
                          background:'#F2F4F7',
                          padding: '10px 12px',
                          border:'1px solid #EEF0F6',
                          borderRadius:'8px',
                          mb:'16px',
                          width:'100%'
                        }}
                        name='5'
                        control={<Checkbox onChange={(e:any)=>handleConsentCheckbox(e)} checked={isConsent5Checked || state?.ticketId}/>}
                        label="I acknowledge that deactivating the account will end the listed patient’s physician-patient relationship with MindWeal and its providers, as an active MindWeal account is required for this relationship." /> 

                      <FormControlLabel 
                        sx={{
                          '.MuiFormControlLabel-label':{
                            marginTop :'10px',
                          },
                          background:'#F2F4F7',
                          padding: '10px 12px',
                          border:'1px solid #EEF0F6',
                          borderRadius:'8px',
                          mb:'16px',
                          width:'100%'
                        }}
                        name='6'
                        control={<Checkbox onChange={(e:any)=>handleConsentCheckbox(e)} checked={isConsent6Checked || state?.ticketId}/>}
                        label="I understand that deactivating the listed patient’s account does not delete any previously submitted records or data." /> 


                      <FormControlLabel 
                        sx={{
                          '.MuiFormControlLabel-label':{
                            marginTop :'10px',
                          },
                          background:'#F2F4F7',
                          padding: '10px 12px',
                          border:'1px solid #EEF0F6',
                          borderRadius:'8px',
                          mb:'16px',
                          width:'100%'
                        }}
                        name='7'
                        control={<Checkbox onChange={(e:any)=>handleConsentCheckbox(e)} checked={isConsent7Checked || state?.ticketId}/>}
                        label="I understand that deactivating the listed patient’s account does not delete their medical records, which are maintained in a separate Electronic Medical Record system outside the MindWeal app." /> 


                      <FormControlLabel 
                        sx={{
                          '.MuiFormControlLabel-label':{
                            marginTop :'10px',
                          },
                          background:'#F2F4F7',
                          padding: '10px 12px',
                          border:'1px solid #EEF0F6',
                          borderRadius:'8px',
                          mb:'16px',
                          width:'100%'
                        }}
                        name='8'
                        control={<Checkbox onChange={(e:any)=>handleConsentCheckbox(e)} checked={isConsent8Checked || state?.ticketId}/>}
                        label="I understand that as a legal guardian, I can request access to my child’s medical records at any time, even after deactivating the account." />

                    </>                    
                  </Box>


                  <Box m='16px 0' pb='24px'>
                    <Typography variant='h2'>Submitted by</Typography>
                    <Grid container columnSpacing={3} mt='24px' mb='24px'>
                      <Grid item xs={12} sm={6}> 
                        <InputLabel htmlFor='name'> Full Name*</InputLabel>
                        <TextField
                          id='name'
                          fullWidth  
                          placeholder='Enter Full Name'
                          onChange={(e:any) => {setName(e.target.value); setEmptyName(false);}}
                          sx={{
                            '& .MuiOutlinedInput-root': {
                              '&.MuiInputBase-root fieldset': {
                                borderColor: emptyName ? '#d32f2f' : '#D0D5DD',
                              },
                            },
                          }}
                          value={name}
                          disabled={state?.ticketId}
                        />
                        {emptyName ? <Typography color={'#d32f2f'} fontSize={'12px'}>Please Enter Name </Typography> : ''}
                      </Grid>
                    </Grid>

                    <InputLabel> Relationship to Patient</InputLabel>                
                    <FormControlLabel 
                      sx={{
                        '.MuiFormControlLabel-label':{
                          marginTop :'10px',
                          fontWeight:700
                        }
                      }}
                      control={<Checkbox onChange={(e:any)=>handleRelationCheckbox(e)} checked={isChecked || state?.ticketId}/>}
                      label={'Primary Guardian'} />      
                  </Box>
                </CardContent> 
                 
              </StyleCard>
            </Grid>
            <Grid justifySelf={'center'} sx={{marginTop:'36px'}}>
              {state?.ticketId && props?.from !=='admin'?
                <Button variant='outlined' sx={{ minWidth: { xs: '140px', sm: '200px' }}} onClick={() => navigate(ROUTE.ACCOUNT_MANAGEMENT)}>  Back  </Button>
                :
                <>  
                  {props?.from === 'admin' ?                  
                    <>                    
                      {state?.status === 'Rejected' || state?.status === 'Approved' ?
                        <Button variant='outlined' onClick={() => navigate(ROUTE.ADMIN_ACCOUNT_MANAGEMENT_REQUEST)}>Back</Button> 
                        : 
                        <>
                          <Button variant='outlined' onClick={() => handleReject()} sx={{ minWidth: { xs: '140px', sm: '200px' }, mr: '20px' }}> Reject </Button>
                          <Button onClick={() => handleConfirmation()} 
                            sx={{
                              minWidth: { xs: '140px', sm: '200px' }, background: '#F04438', color: '#fff',
                              '&:hover': {
                                background: '#F04438'
                              }
                            }}           
                          > {state?.status === 'Transfer Failed' ? 'Retry' :  'Deactivate'} </Button>
                        </>
                      }                    
                    </>
                    :
                    <>
                      <Button variant='outlined' sx={{ marginRight: '20px', minWidth: { xs: '140px', sm: '200px' } }}
                        onClick={() => { props?.from === 'admin' ? navigate(ROUTE.ADMIN_ACCOUNT_MANAGEMENT_REQUEST) : navigate(ROUTE.ACCOUNT_MANAGEMENT); }}>  Cancel  </Button>

                      <Button disabled={disableSubmitButton || !allConsentsChecked || state?.ticketId} type='submit' onClick={onSubmitForm}
                        sx={{ minWidth: { xs: '140px', sm: '200px' } }}> Submit </Button>
                    </>
                  }
                  
                </>}
            </Grid>
            <Dialog  aria-labelledby="success-dialog"  open={showSuccessModal} >
              <DialogTitle id="simple-dialog-title" sx={{padding:'30px 0 0 0'}}>
                <Typography variant='h2'> {changeSuccessMessage ? 'Last request is Under Review':'Request Submitted Successfully'} </Typography></DialogTitle>
              <DialogContent>
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  mt={'36px'}
                > 
                  <Typography variant='h3'> {changeSuccessMessage ? 'Please wait until we complete your last request.' : 'We will review and get back to you soon.'} </Typography>           
                </Grid>
              </DialogContent>
              <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button onClick={() => navigate(ROUTE.ACCOUNT_MANAGEMENT)} sx={{mb:'24px'}}> OKAY</Button>
              </DialogActions>
            </Dialog>

            <Dialog  aria-labelledby="success-dialog"  open={showConfirmationModal} 
              sx={{ 
                '&.MuiDialog-paper':{
                  p:'24px'
                }
              }}>
              <DialogTitle id="simple-dialog-title" sx={{padding:'30px 30px 0 '}}>
                <Typography variant='h2'> {changeSuccessMessage ? 'Account Deactivated Successfully' : 'Deactivate Account'} </Typography></DialogTitle>
              {!changeSuccessMessage ?
                <DialogContent>
                  <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    mt={'36px'}
                  > 
                    <Typography sx={{fontSize:'18px', textAlign:'center'}}> Deactivating a patient account requires approval from the patient’s primary provider.</Typography>    
                    <Typography sx={{fontSize:'18px', mt:'20px'}}>Have you obtained the necessary approval?</Typography>       
                  </Grid>
                </DialogContent> : null}
              <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                {changeSuccessMessage ?
                  <Button onClick={() => navigate(ROUTE.ADMIN_ACCOUNT_MANAGEMENT_REQUEST)} sx={{ mr: '20px',mt:'20px' }}> Done</Button>
                  :
                  <>
                    <Button variant='outlined' onClick={() => setShowConfirmationModal(false)} sx={{mr: '20px' }}> Cancel</Button>
                    <Button onClick={() => handleAction('Deactivate')} sx={{ minWidth: { xs: '140px', sm: '200px' }, background: '#F04438', color: '#fff',
                      '&:hover': {
                        background: '#F04438'
                      }
                    }}> Deactivate </Button>
                  </>}      
              </DialogActions>
            </Dialog>

            <Dialog  aria-labelledby="success-dialog"  open={showRejectionModal} >
              <DialogTitle id="simple-dialog-title" sx={{padding:'30px 0 0 0'}}>
                <Typography variant='h2'> Reject Request </Typography></DialogTitle>
              <DialogContent>
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="left"
                  justifyContent="left" 
                  pt='20px'                                   
                > 
                  <InputLabel>Reason for Rejection*</InputLabel>
                  <TextField
                    multiline
                    placeholder='Enter reason here'
                    onChange={(e:any) => setReasonForRejection(e?.target?.value)}
                    rows={4}
                    variant='filled'                    
                  />
                </Grid>
              </DialogContent>
              <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button variant='outlined' sx={{mb:'24px'}} onClick={() => {setShowRejectionModal(false); setReasonForRejection('');}}>Cancel</Button>
                <Button onClick={()=>handleAction('Rejected')} sx={{mb:'24px'}} disabled={reasonForRejection === undefined || reasonForRejection === ''}> Reject</Button>
              </DialogActions>
            </Dialog>
          </Container>
        </Grid >
      </Grid >
    </Page>
  );
};

export default DeactivateAccount;
 