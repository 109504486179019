import React, { useContext, useEffect, useState } from 'react';
import {
  Box, Grid, InputLabel, Typography, Button, Dialog, DialogActions,
  DialogContent, IconButton, TextField,
  InputAdornment
} from '@mui/material';
import CreditCardOffOutlinedIcon from '@mui/icons-material/CreditCardOffOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch } from 'store/hooks';
import { getTransactionHistory, chargePayment, paymentAction } from 'store/slices/paymentSlice';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { chargePaymentSchema } from 'utils/schema';
import { toast } from 'react-toastify';
import { getPaymentStatus } from 'store/slices/patientInfoSlice';
import { AuthContext } from 'utils/AuthContext';


const Payments = (props: any) => {

  const {
    handleSubmit,
    register,
    formState: { errors, isDirty, isValid },
  } = useForm({
    resolver: yupResolver(chargePaymentSchema),
    mode: 'onChange',
  });

  const dispatch = useAppDispatch();
  const { user } = useContext(AuthContext);

  const [transactionDate, setTransactionDate] = useState<string>('');
  const [amount, setAmount] = useState<any>();
  const [status, setStatus] = useState<string>('');
  const [transactionId, setRecentTransactionId] = useState<string>('');
  const [transactionPostedToDrChrono, setTransactionPostedToDrChrono] = useState<boolean>(false);
  const [transactionHistory, setTransactionHistory] = useState<any>([]);
  const [displayChargePaymentModal, setDisplayChargePaymentModal] = useState<boolean>(false);
  const [displayPaymentTransactionModal, setDisplayPaymentTransactionModal] = useState<boolean>(false);
  const [noCard, setNoCard] = useState<boolean>(false);
  const [cardExpired, setCardExpired] = useState<boolean>(false);

  useEffect(() => {
    getHistory();
    dispatch(getPaymentStatus(props.patientId))
      .unwrap()
      .then((response: any) => {
        if (response?.status === 200 && response.data !== null) {
          if (response?.data?.creditCardStatus === '') {
            setNoCard(true);
          } else if (response?.data?.creditCardStatus === 'Inactive') {
            setCardExpired(true);
          }
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.patientId]);

  const getHistory = () => {
    dispatch(getTransactionHistory(props?.patientId))
      .unwrap()
      .then((response: any) => {
        if (response?.status === 200 && response?.data?.length > 0) {
          setTransactionDate(response?.data[0]?.transactionDate);
          setAmount(response?.data[0].amount);
          setStatus(response?.data[0]?.status);
          setRecentTransactionId(response?.data[0]?.id);
          setTransactionPostedToDrChrono(response?.data[0]?.transactionPostedToDrChrono);
          setTransactionHistory(response?.data);
        }
      });
  };

  const handleChargePayment = () => {
    setDisplayChargePaymentModal(!displayChargePaymentModal);
  };

  const handlePaymentHistory = () => {
    setDisplayPaymentTransactionModal(!displayPaymentTransactionModal);
  };

  const onSubmitForm = (formdata: any) => {
    const data = {
      description: formdata.description,
      amount: formdata.amount,
      chargeType: 'VMA_INITIATED',
      patientId: props?.patientId
    };

    dispatch(chargePayment(data))
      .unwrap()
      .then((response: any) => {
        if (response?.status === 200) {
          setDisplayChargePaymentModal(false);
          getHistory();
          toast(`Payment of $${formdata.amount} has been processed successfully.`, {
            type: 'success',
            icon: true
          });

        }
      })
      .catch((e: any) => {
        if (e.err.errorCode === 8001) {
          toast('No valid payment available.', {
            type: 'error',
            icon: true
          });
        } else if (e.err.errorCode === 8002) {
          toast('Payment could not be processed.', {
            type: 'error',
            icon: true
          });
        }
        setDisplayChargePaymentModal(false);
      });
  };

  const handleAction = (action: string, id: string) => {
    const data = {
      id: id,
      action: action
    };

    dispatch(paymentAction(data))
      .unwrap()
      .then((response: any) => {
        if (response?.success === 200) {
          toast('Action completed successfully', {
            type: 'success',
            icon: true
          });
        }
      });
  };

  return (
    <>
      <Box
        width={'100%'}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'center'}
      >
        {noCard || cardExpired ?
          <Box
            sx={{
              border: '1px solid #B42318', borderRadius: '12px', gap: '12px', mb: '16px',
              padding: '12px 16px 6px 16px',
              background: '#FFFBFA'
            }}
            minWidth='100%'>
            <Grid container>
              <Grid item xs={1}>
                <CreditCardOffOutlinedIcon color='error' />
              </Grid>
              <Grid item xs={11}>
                <Typography>
                  {noCard ? 'No card is currently saved. Please request the account holder' +
                    ' to add a payment method before proceeding with the charge.' :
                    'The saved credit/debit card has expired. Please ask the account holder' +
                    'to update their payment details before proceeding with the charge.'}
                </Typography>
              </Grid>
            </Grid>
          </Box> : null}

        <Grid container spacing={2}>
          <Grid item xs={6} display={'flex'} justifyContent='space-between' alignItems={'center'}>
            <Typography variant='h2' sx={{ fontSize: 20, fontWeight: '500', lineHeight: 1.4 }}>
              Payments
            </Typography>
          </Grid>
          {user?.permissions.map((p: any) => {
            if (p === 'PAYMENT_CREATE') {
              return (
                <Grid item xs={6} display={'flex'} justifyContent='flex-end' key={p}>
                  <Button variant='text'
                    sx={{ fontSize: 14, fontWeight: '500', lineHeight: 1.4 }}
                    onClick={() => handleChargePayment()}
                    disabled={noCard || cardExpired}
                  > $ CHARGE PAYMENT </Button>
                </Grid>
              );
            }
            return null;
          })}

          <Grid item xs={12}>
            <InputLabel htmlFor='date'>Recent Transaction </InputLabel>
            {transactionHistory?.length > 0 ?
              <Box
                sx={{ border: '1px solid #FFC44D', borderRadius: '12px', gap: '12px', padding: '12px 16px 6px 16px' }}>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography> Transaction Date </Typography>
                    <Typography sx={{ fontWeight: 700, mt: '5px' }}>{transactionDate}</Typography>

                  </Grid>
                  <Grid item xs={4}>
                    <Typography> Amount </Typography>
                    <Typography sx={{ fontWeight: 700, mt: '5px' }}> ${amount}</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography>Status</Typography>
                    <Typography
                      sx={{
                        border: status === 'PENDING' ? '1px solid #3467FF' :
                          status === 'SUCCESS' ? '1px solid #12B76A' : '1px solid #B42318',
                        borderRadius: '8px',
                        paddingTop: '2px',
                        background: status === 'PENDING' ? '#F5F8FF' : status === 'SUCCESS' ? '#F6FEF9' : '#FFFBFA',
                        width: 'fit-content',
                        padding: '2px 10px',
                        color: status === 'PENDING' ? '#3467FF' : status === 'SUCCESS' ? '#12B76A' : '#B42318',
                        fontSize: '12px',
                        mt: '5px'
                      }}>
                      {status}</Typography>
                  </Grid>
                </Grid>
                {status === 'PENDING' ?
                  <Box mt={'8px'}>
                    <Button variant='text' sx={{ fontSize: '13px', paddingLeft: '0px' }}
                      disabled={transactionPostedToDrChrono}
                      onClick={() => handleAction('update_drchrono', transactionId)}>Post in Dr. Chrono</Button>
                    <Button variant='text' sx={{ fontSize: '13px' }}
                      onClick={() => handleAction('paid', transactionId)}> Mark as Paid</Button>
                    <Button variant='text' sx={{ fontSize: '13px' }}
                      onClick={() => handleAction('cancelled', transactionId)}>Cancel</Button>
                  </Box> : ''}
              </Box> :
              <Box>
                <Typography> No transactions are found.</Typography>
              </Box>}

            {transactionHistory?.length > 1 ?
              <Button variant='text' sx={{ paddingLeft: '2px', fontSize: '14px', mt: '10px' }}
                onClick={() => handlePaymentHistory()}>
                VIEW COMPLETE TRANSACTION HISTORY</Button> : null}
          </Grid>
        </Grid>
      </Box>

      <Dialog open={displayChargePaymentModal}>
        <Box width={'100%'} component='form' onSubmit={handleSubmit((formData) => onSubmitForm(formData))}>
          <IconButton
            aria-label='close'
            onClick={handleChargePayment}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>

            <Grid container spacing={4} display={'flex'}>
              <Grid item xs={12} alignItems={'center'} textAlign={'center'}>
                <Typography variant='h2' textAlign={'center'} pb={2}>
                  Charge Payment
                </Typography>
                <Typography> Charge the payment directly from the stored credit card,
                  and notification will be sent to the cardholder.</Typography>
              </Grid>
              <Grid
                width={'100%'}
                display={'flex'}
                flexDirection={'column'}
                sx={{ marginLeft: '50px' }}

              >
                <Grid alignItems={'start'}>
                  <Typography sx={{ fontWeight: 700 }}>Amount*</Typography>
                  <TextField
                    id='amount'
                    fullWidth
                    {...register('amount')}
                    placeholder='Enter Amount'
                    inputProps={{ maxLength: 6 }}
                    InputProps={{
                      startAdornment: <InputAdornment position='start'>$</InputAdornment>
                    }}
                    error={!!errors.amount}
                    helperText={errors?.amount?.message}

                  />
                </Grid>
                <Grid alignItems={'start'} mt='20px'>
                  <Typography sx={{ fontWeight: 700 }}>Description*</Typography>
                  <TextField
                    id='description'
                    fullWidth
                    {...register('description')}
                    placeholder='Write a reason for the payment here'
                    error={!!errors.description}
                    helperText={errors?.description?.message}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button variant='contained' type='submit' disabled={!isDirty || !isValid}>
              PROCEED TO PAYMENT</Button>
          </DialogActions>
        </Box>
      </Dialog>

      <Dialog open={displayPaymentTransactionModal}>
        <Box width={'100%'}>
          <IconButton
            aria-label='close'
            onClick={handlePaymentHistory}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>

            <Grid container spacing={4} display={'flex'}>
              <Grid item xs={12} alignItems={'center'} textAlign={'center'}>
                <Typography variant='h2' textAlign={'center'} pb={2}>
                  Transaction History
                </Typography>
              </Grid>
              <Grid
                width={'100%'}
                display={'flex'}
                flexDirection={'column'}
                sx={{ marginLeft: '50px' }}
              >
                {transactionHistory?.length === 0 ? 'No Transactions' :
                  transactionHistory?.length > 0 && transactionHistory?.map((t: any) => {
                    return (
                      <Box key={t.transactionId}
                        sx={{
                          border: '1px solid #FFC44D',
                          borderRadius: '12px',
                          gap: '12px',
                          padding: '12px 16px 6px 16px',
                          marginBottom: '20px'
                        }}>
                        <Grid container spacing={2} mb={'8px'}>
                          <Grid item xs={4}>
                            <Typography> Transaction Date </Typography>
                            <Typography sx={{ fontWeight: 700, mt: '5px' }}>{t?.transactionDate}</Typography>

                          </Grid>
                          <Grid item xs={4}>
                            <Typography> Amount </Typography>
                            <Typography sx={{ fontWeight: 700, mt: '5px' }}> ${t?.amount}</Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography>Status</Typography>
                            <Typography
                              sx={{
                                border: t.status === 'PENDING' ? '1px solid #3467FF' :
                                  t.status === 'SUCCESS' ? '1px solid #12B76A' : '1px solid #B42318',
                                borderRadius: '8px',
                                paddingTop: '2px',
                                background: t.status === 'PENDING' ? '#F5F8FF' :
                                  t.status === 'SUCCESS' ? '#F6FEF9' : '#FFFBFA',
                                width: 'fit-content',
                                padding: '2px 10px',
                                color: t.status === 'PENDING' ? '#3467FF' :
                                  t.status === 'SUCCESS' ? '#12B76A' : '#B42318',
                                fontSize: '12px',
                                mt: '5px'
                              }}>
                              {t?.status.charAt(0).toUpperCase() + t.status.slice(1)}</Typography>
                          </Grid>
                        </Grid>
                        {t.status === 'PENDING' ?
                          <Box>
                            <Button variant='text' sx={{ fontSize: '13px', paddingLeft: '0px' }}
                              onClick={() => handleAction('update_drchrono', t.transactionId)}>
                              Post in Dr. Chrono</Button>
                            <Button variant='text' sx={{ fontSize: '13px' }}
                              onClick={() => handleAction('paid', t.transactionId)}> Mark as Paid</Button>
                            <Button variant='text' sx={{ fontSize: '13px' }}
                              onClick={() => handleAction('cancelled', t.transactionId)}>Cancel</Button>
                          </Box> : ''}
                      </Box>
                    );
                  })
                }
              </Grid>
            </Grid>
          </DialogContent>
        </Box>
      </Dialog>
    </>
  );
};

export default Payments;
