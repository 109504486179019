import { CardMedia, CircularProgress, ClickAwayListener, Grid, 
  Tooltip, TooltipProps, Typography, styled, tooltipClasses } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getGuardianDetails } from 'store/slices/userSlice';
import {getConversationGroupDetails, joinConversation} from 'store/slices/secureMessageSlice';
import {ROUTE} from 'route/RouteEnums';
import chat from 'assets/images/chat.svg';
import online from 'assets/images/online.svg';
import { formatPhoneNumber } from 'utils/functions';

const StyledTooltip = styled(({ className, color, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltipArrow}`]: {
    backgroundColor: '#fff!important',
    boxShadow: theme.shadows[1]
  },
  [`& .${tooltipClasses.arrow}`]: {
    '&:before': {
      border: '1px solid #fff',
      color:'#fff',              
    },      
  },
  [`& .${tooltipClasses.tooltip}`]:{
    border: '1px solid #fff',
    padding:'6px',
    borderRadius:'8px'
  }
}));

const PrimaryContact = (props: { patientId: any }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isGuardianDetailsFetching = useAppSelector((state) => state.user.loaders.isProfileDetailsFetched);
  const [primaryContact, setPrimaryContact] = useState({
    email: '',
    mobileNumber: '',
  });
  const [groupDetails, setGroupDetails] = useState<any[] | null>();
  const [open, setOpen] = useState<boolean>(false);


  useEffect(() => {
    getGuardian();
    //eslint-disable-next-line
  }, []);

  const getGuardian = () => {
    if (props?.patientId) {
      const data = { userId: Number(props?.patientId) };
      dispatch(getGuardianDetails(data))
        .unwrap()
        .then(async (response: any) => {
          if (response?.status === 200) {
            const guardian: any = response.data.find((guardianObj: any) => guardianObj.guardianCategory === 'primary');
            if (guardian) {
              const primaryContact = { mobileNumber: guardian?.mobileNumber, email: guardian?.email };
              setPrimaryContact(primaryContact);
            }
          }
        });
    }
  };

  const handleTooltipOpen=()=>{
    setOpen(true);
    dispatch(getConversationGroupDetails(props?.patientId))
      .unwrap()
      .then((response:any) =>{
        if(response?.status === 200){
          setGroupDetails(response.data);
        }
      });
  };

  const handleTooltipClose=()=>{
    setOpen(false);
  };

  const handleJoinConversation = (e: any, g:any) => {
    e.stopPropagation();
    dispatch(joinConversation(g.groupId))
      .unwrap()
      .then((response: any) => {
        if (response?.status === 200) {
          navigate(`${ROUTE.ADMIN_MESSAGES}`);
        }
      });
  };

  return (
    <Grid container  paddingBottom={2} paddingTop={2.5}>
      {isGuardianDetailsFetching ? (
        <Grid item xs={12} display={'flex'} alignItems={'center'} justifyContent={'center'} flex={1}>
          <CircularProgress />
        </Grid>
      ) : (
        <Grid
          item
          xs={12}
          md={8}
          display={'flex'}
          flexDirection={'column'}
          alignItems={'flex-start'}
          justifyContent='space-around'
        >
          <Typography variant='h2' sx={{ fontSize: 16, fontWeight: '500', lineHeight: 1.7 }}>
            Primary Contact
          </Typography>
          <Typography variant='h4' sx={{ fontSize: 14, fontWeight: '400', lineHeight: 1.5 }}>
            Phone:{' '}
            {primaryContact?.mobileNumber ? 
              formatPhoneNumber(primaryContact?.mobileNumber)
              : 'Not Available'}
          
                    
          </Typography>
          <Typography variant='h4' sx={{ fontSize: 14, fontWeight: '400', lineHeight: 1.5 }}>
            Email: {primaryContact?.email ? primaryContact?.email : 'Not Available'}
          </Typography>
          <Grid container sx={{mt:'8px'}}>  
            <Grid item>              
              <CardMedia src={chat} component='img' sx={{ width: '24px', height: 'auto' }} /> 
            </Grid>
            <Grid item sx={{pt:'4px'}}>
              <ClickAwayListener onClickAway={handleTooltipClose}>
                <StyledTooltip
                  arrow
                  onClose={handleTooltipClose}
                  open={open}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={
                    <>
                      {groupDetails === null || groupDetails === undefined ? 
                        <CircularProgress/> :
                        groupDetails?.length !==0 ?
                          <>
                            <Grid container 
                              spacing={1}
                              direction='row'
                              alignItems='center'
                              justifyContent='space-between'>
                              <Grid item xs={11} style={{cursor:'default'}}>
                                <Typography sx={{ fontSize: '14px', fontWeight: 700, color:'#1C1F24', mb:'16px' }}>
                    Select Type to Start
                                </Typography> 
                              </Grid>
                              <Grid item alignItems={'right'}>
                                <Typography  sx={{ fontSize: '14px', fontWeight: 700, color:'#1C1F24', mb:'16px',
                                  cursor:'pointer' }}
                                onClick={(e: any) => { e.stopPropagation(); handleTooltipClose(); }}> X </Typography>
                              </Grid>
                            </Grid>
                            <Grid container>
                              {groupDetails?.map((g:any) => {
                                return(
                                  <>
                                    <Grid item xs={9}>
                                      <Typography 
                                        sx={{ color: '#3467FF', fontSize: '14px', 
                                          fontWeight: 500, mb: '16px', cursor: 'pointer'
                                        }} 
                                        onClick={(e:any) => { handleJoinConversation(e,g); }}
                                      >
                                        {g.groupName}
                                      </Typography>                         
                                    </Grid>
                                    <Grid item xs={3} >
                                      {g.activeConversation ? 
                                        <CardMedia src={online} component='img' 
                                          sx={{ width: '10px', height: '10px', mt: '4px', ml:'20px' }} /> 
                                        : ''}
                                    </Grid>
                                  </>
                                );
                              })}
                            </Grid>
                          </> :
                          <Grid container 
                            spacing={1}
                            direction='row'
                            alignItems='center'
                            justifyContent='space-between'
                            p='0 10px'                          
                          >
                            <Grid item xs={11} style={{cursor:'default'}}>
                              <Typography sx={{ fontSize: '14px', fontWeight: 700, color:'#1C1F24', mb:'16px' }}>
                              No groups
                              </Typography> 
                            </Grid>
                            <Grid item xs={1} >
                              <Typography  sx={{ fontSize: '14px', fontWeight: 700, color:'#1C1F24', mb:'16px', 
                                cursor: 'pointer'
                              }}
                              onClick={(e: any) => { e.stopPropagation(); handleTooltipClose(); }}> X </Typography>
                            </Grid>
                            <Typography sx={{ fontSize: '14px', color:'#1C1F24', mb:'16px' }}>
                             There are no groups available.
                            </Typography>
                          </Grid>  
                      }
                    </>
                  }
                  placement='right-end'                
                  PopperProps={{
                    disablePortal: true,
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [10, 0],
                        },
                      },
                    ],
                  }}
                >
                  <Typography sx={{pl:'3px', color:'#3467FF', fontSize:'12px',fontWeight:700, cursor:'pointer'}}
                    onClick={() => { handleTooltipOpen(); }}
                  > 
                JOIN CONVERSATION </Typography>
                </StyledTooltip>
              </ClickAwayListener>
            </Grid> 
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default PrimaryContact;
