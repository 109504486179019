import React, { useState,useEffect } from 'react';
import {
  Grid, Typography, InputLabel, TextField, IconButton, Popper,
  Box, List, ListItem, Button
} from '@mui/material';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

import COLORS from 'theme/colors';
import { changePasswordSchema, checkPasswordCriteria } from 'utils/schema';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

const ChangePassword = (props:any) => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(changePasswordSchema),
    mode: 'onChange',
  });
      
  const [anchorEl, setAnchorEl] = useState({
    passwordPopOver: null,
    mobilePopOver: null,
  });
  const [passwordCriteria, setPasswordCriteria] = useState<any>([]);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setPasswordCriteria(checkPasswordCriteria(getValues('password')));
  }, [getValues]);

  const handlePopoverOpen = (event: any, elementName: string) => {
    setAnchorEl({ ...anchorEl, [elementName]: event.currentTarget });
  };

  const handlePopoverClose = () => {
    setAnchorEl({ passwordPopOver: null, mobilePopOver: null });
  };

  const open = {
    passwordPopOver: Boolean(anchorEl.passwordPopOver),
    mobilePopOver: Boolean(anchorEl.mobilePopOver),
  };

  const onSubmitForm=(formdata:any)=>{
    console.log('fff',formdata);
    
  };

  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        component='form'
        onSubmit={handleSubmit((formData: any) => onSubmitForm(formData))}
      >
        <Typography>
            Enter a new password for your account.</Typography>
        <Grid item xs={3} mt={'20px'}>
          <Grid item xs={12}>
            <InputLabel htmlFor='phone'>Password*</InputLabel>
            <TextField
              type='password'
              required
              placeholder='Enter your password'
              id='password'
              autoComplete='password'
              {...register('password')}
              error={!!errors.password}
              helperText={errors.password ? errors.password?.message : ''}
              onFocus={(event) => handlePopoverOpen(event, 'passwordPopOver')}
              onChange={(event) => {
                setPasswordCriteria(checkPasswordCriteria(event.target.value.toString()));
                register('password').onChange(event);
              }}
              onBlur={handlePopoverClose}
              InputProps={{
                endAdornment: (
                  <>
                    <IconButton
                      area-aria-owns='password-popover'
                      aria-haspopup='true'
                      onMouseOver={(event) => handlePopoverOpen(event, 'passwordPopOver')}
                      onMouseOut={handlePopoverClose}
                    >
                      <HelpOutlineIcon />
                    </IconButton>
                    <Popper
                      id='password-popover'
                      placement='auto-start'
                      open={open.passwordPopOver}
                      anchorEl={anchorEl.passwordPopOver}
                      disablePortal={true}
                      sx={{
                        '&.MuiPopper-root':{
                          zIndex:9999
                        }
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: COLORS.White,
                          padding: '12px',
                          boxShadow: '0 4px 6px 0 rgba(0, 0, 0, 0.3)',
                          borderRadius: '8px',
                        }}
                      >
                        <Typography variant='subtitle2' gutterBottom>
                            Password Criteria
                        </Typography>
                        <List
                          sx={{
                            listStyleType: 'disc',
                            pl: 3,
                            margin: 0,
                          }}
                        > 
                          {passwordCriteria.length > 0 &&
                            passwordCriteria.map((condition: any) => {
                              return (
                                <ListItem
                                  key={condition.name}
                                  sx={{
                                    display: 'list-item',
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  <Typography
                                    variant='body2'
                                    display='block'
                                    sx={{
                                      color: errors.password && !condition.isValid ? COLORS.Error : COLORS.Text,
                                    }}
                                  >
                                    {condition.message}
                                  </Typography>
                                </ListItem>
                              );
                            })}
                        </List>
                      </Box>
                    </Popper>
                  </>
                ),
              }}
              sx={{
                '.MuiFormHelperText-root.Mui-error':{
                  maxWidth:'200px'
                }
              }}
            />
          </Grid>
          <Grid item xs={12} m={'12px 0'}>
            <InputLabel htmlFor='confirmPwd'>Confirm Password*</InputLabel>
            <TextField
              required
              placeholder='Retype your password'
              type={isVisible ? 'text' : 'password'}
              id='confirmPwd'
              autoComplete='new-password'
              {...register('confirmPassword')}
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword ? errors.confirmPassword?.message : ''}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={() => setIsVisible(!isVisible)}>
                    {isVisible ? <RemoveRedEyeOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                  </IconButton>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Grid container justifyContent="center" mt='10px'>
          <Button sx={{ textAlign: 'right', maxWidth:'100px', minWidth:'100px'}} variant='outlined' 
            onClick={props.onCancel}> Cancel </Button> 
          <Button type='submit' sx={{ textAlign: 'right',maxWidth:'100px', minWidth:'100px', ml:'10px' }}
            disabled={!isValid}> Submit </Button> 
        </Grid>
      </Grid>
    </>
  );
};

export default ChangePassword;