import { Box, IconButton } from '@mui/material';
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid';
import HealthAndSafetyOutlinedIcon from '@mui/icons-material/HealthAndSafetyOutlined';

import COLORS from 'theme/colors';
import { NO, YES } from './constants';

export const CustomToolbar = () => {
  return (
    <GridToolbarContainer sx={{ backgroundColor: 'transparent', marginBottom: '5px' }}>
      <GridToolbarColumnsButton
        size='small'
        sx={{
          backgroundColor: 'transparent',
          color: '#3467FF',
          minWidth: '100px',
          boxShadow: 'none',
          paddingLeft: '0px',
          '&:hover': { backgroundColor: 'transparent', boxShadow: 'none' },
        }}
      />
      <GridToolbarFilterButton
        sx={{
          backgroundColor: 'transparent',
          color: '#3467FF',
          minWidth: '100px',
          boxShadow: 'none',
          paddingLeft: '0px',
          '&:hover': { backgroundColor: 'transparent', boxShadow: 'none' },
        }}
      />
    </GridToolbarContainer>
  );
};

export const EmergencyLabel = (props: any) => {
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        Emergency Contact
        {props.SGEmergencyContactValue === NO && props.selectedTab === 2 && (
          <IconButton>
            <HealthAndSafetyOutlinedIcon fontSize='small' sx={{ color: COLORS.Error }} />
          </IconButton>
        )}
      </Box>
    </>
  );
};

export const SecoundaryLabel = (props: any) => {
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        Secondary Guardian
        {props.SGEmergencyContactValue === YES && props.selectedTab === 1 && (
          <IconButton>
            <HealthAndSafetyOutlinedIcon sx={{ color: COLORS.Error }} fontSize='small' />
          </IconButton>
        )}
      </Box>
    </>
  );
};

export const PrimaryLabel = (props: any) => {
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        Primary Guardian
        {props.PGEmergecyContact === YES && props.selectedTab === 0 && (
          <IconButton>
            <HealthAndSafetyOutlinedIcon sx={{ color: COLORS.Error }} fontSize='small' />
          </IconButton>
        )}
      </Box>
    </>
  );
};

export const formatPhoneNumber=(phoneNumberString:any)=> {  
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  let match:any = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if(match === null && cleaned.length === 11){    
    match = cleaned.slice(1).match(/^(\d{3})(\d{3})(\d{4})$/); 
  }
  
  if (match) {
    return  '('+ match[1]+') ' + match[2] + '-' + match[3];
  }
  return null;
};