import {
  Alert,
  Box,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { useEffect, useRef, useState } from 'react';
import { guardianSchema } from 'utils/schema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { GaurdianRelation, YES, permissions } from 'utils/constants';
import { useAppSelector } from 'store/hooks';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { toast } from 'react-toastify';
import ReactPhoneInput from 'react-phone-input-material-ui';
import { useAuth } from 'utils/AuthContext';
import { Restricted } from 'components/Restricted';

//PrimaryGaurdian component
const PrimaryGaurdian = (props: any) => {
  //validation schema PG
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
    watch,
    setValue,
    reset,
    clearErrors,
  } = useForm({
    resolver: yupResolver(guardianSchema),
    mode: 'onChange',
  });

  //states  for PG
  const [open, setOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const emergencyContactTooltipRef = useRef<HTMLDivElement>(null);
  const [agreementMessage, setAgreementMessage] = useState(false);
  const guardians = useAppSelector((state) => state.user.guardians);
  const isProfileDetailsFetched = useAppSelector((state) => state.user.loaders.isProfileDetailsFetched);

  const { user } = useAuth();

  const setPrimaryGuardianDetails = () => {
    if (props?.primaryGaurdian) {
      Object.keys(props?.primaryGaurdian).forEach((key: any) => {
        if (key === 'isEmergency' || key === 'isActiveCustody') {
          setValue(key, props?.primaryGaurdian[key] ? 'yes' : 'no', { shouldValidate: true, shouldDirty: false });
          if (key === 'isActiveCustody') setAgreementMessage(props?.primaryGaurdian[key]);
        } else if (key === 'other' && props?.primaryGaurdian['relation'] !== 'other')
          setValue(key, '', { shouldValidate: true, shouldDirty: false });
        else if (key === 'mobileNumber'){
          const str = props?.primaryGaurdian?.mobileNumber.replaceAll(' ','');
          const mobile = str.replace(/[^\w\s]/g,'');
          const phoneNumbers = parseInt(mobile);
          if(phoneNumbers.toString().length === 10){                    
            setValue('mobileNumber', `1${phoneNumbers}`, { shouldValidate: true, shouldDirty: false });
          }else if(phoneNumbers.toString().length === 11){                    
            setValue('mobileNumber', `${phoneNumbers}`, { shouldValidate: true, shouldDirty: false });
          }
        
        }else setValue(key, props?.primaryGaurdian[key], { shouldValidate: true, shouldDirty: false });
      });
      if (props?.from === 'admin') clearErrors();
    } else if (props?.from !== 'admin') {
      ['userId', 'isEmergency', 'firstName', 'lastName', 'email', 'relation', 'mobileNumber'].forEach((key: any) => {
        if (key === 'userId') setValue('id', user ? user[key] : '', { shouldValidate: true, shouldDirty: false });
        else if (key === 'isEmergency') setValue(key, YES, { shouldValidate: true, shouldDirty: false });
        else if (key === 'relation')
          setValue(
            'relation',
            // eslint-disable-next-line eqeqeq
            user && user[key] == 'Mother' ? 'Mother' : user && user[key] == 'Father' ? 'Father' :
            // eslint-disable-next-line eqeqeq
              user && user[key] == 'Guardian' ? 'Guardian' : ''
          );
        else if (key === 'mobileNumber' && user[key].length === 10){
          setValue(key, `1${user[key]}`, { shouldValidate: false, shouldDirty: false });
        }else setValue(key, user ? user[key] : '', { shouldValidate: true, shouldDirty: false });
      });
      setValue('other', '', { shouldValidate: true, shouldDirty: false });
    }
  };

  useEffect(() => {
    setPrimaryGuardianDetails();
    //eslint-disable-next-line
  }, [guardians]);

  //handle toolkit (clickout)u
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target as Node)) {
        setOpen(false);
      }
      if (emergencyContactTooltipRef.current && !emergencyContactTooltipRef.current.contains(event.target as Node)) {
        setTooltipOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    setPrimaryGuardianDetails();
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
    //eslint-disable-next-line
  }, []);

  //tooltip content
  const tooltipContent = (
    <>
      <Typography variant='body1'>
        Please email a copy of the agreement to{' '}
        <Link href='mailto:newpatient@mindweal.com' variant='body2'>
          newpatient@mindweal.com
        </Link>
        , including the patient&apos;s name and any relevant details. Use &apos;Parental Agreement - [Patient&apos;s
        Name]&apos; as the subject line. Thank you !
      </Typography>
    </>
  );

  //handle PG ActiveParenting (default value NO)
  const handlePGActiveParenting = (e: any) => {
    if (e.target.value === YES) {
      setAgreementMessage(true);
    } else {
      setAgreementMessage(false);
    }
    setValue('isActiveCustody', e.target.value, { shouldValidate: true });
  };

  //submit PG form
  const onSubmitForm = async (formData: any) => {    
    function formatPhoneNumber(phoneNumberString:any) {
      const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
      const match = cleaned.match(/^(1)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        const intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
      }
      return null;
    }
    if(isValid && isDirty){
      const payload = guardians
        .filter((guardian: any) => guardian.guardianCategory !== formData.guardianCategory)
        .concat({
          ...formData,
          id: formData?.id > 0 ? formData.id : null,
          isEmergency: formData?.isEmergency === YES ? true : false,
          guardianCategory: 'primary',
          isActiveCustody: formData?.isActiveCustody === 'yes' ? true : false,
          other: formData?.relation === 'other' ? formData?.other : '',
          mobileNumber: formData?.mobileNumber ? formatPhoneNumber(formData?.mobileNumber.slice(1)) : '',
        });
      props.onUpdateGuardian(payload, () => resetData(formData));
    }else{
      toast('No changes to update.',{
        type:'info',
        icon:true
      });
    }
  };

  const resetData = (formData: any) => {
    const message1 = 'Primary guardian updated successfully.';
    const message2 = 'Primary guardian updated successfully. You can upload your picture.';
    toast(props.pictureAvailable ? message1 : message2, {
      type: 'success',
      icon: true,
    });
    reset(formData);
  };

  const emergencyContactTooltip = (
    <>
      <h3>Emergency Contact Person</h3>
      <p>This person will be contacted in case of emergencies.</p>
    </>
  );
  //return Primary Gaurdian Information component
  return (
    <Container component='main' maxWidth={'sm'} sx={{ padding: '0px !important' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box sx={{ mt: 1 }} component='form' noValidate onSubmit={handleSubmit((formData) => onSubmitForm(formData))}>
          <Box
            sx={{
              marginBottom: 2,
            }}
          >
            <Typography variant='body1' gutterBottom sx={{ backgroundColor: '#FFFBF1', padding: '8px 8px 4px 8px' }}>
              <b>IMPORTANT:</b> Primary Guardian&apos;s cell phone and email will be our main communication channels.
            </Typography>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor='PGfirstName'>{props?.from !== 'admin' ? 'First Name*' : 'First Name'}</InputLabel>
              <TextField
                id='PGfirstName'
                autoComplete='PGfirstname'
                placeholder="Enter guardian's first name"
                required
                fullWidth
                {...register('firstName')}
                error={!!errors.firstName}
                helperText={errors.firstName ? errors.firstName?.message?.toString() : ''}
                disabled={props?.from === 'admin'}
                inputProps={{
                  maxLength: 255,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor='PGlastName'>{props?.from !== 'admin' ? 'Last Name*' : 'Last Name'}</InputLabel>
              <TextField
                id='PGlasttName'
                autoComplete='PGlastname'
                placeholder="Enter guardian's last name"
                required
                fullWidth
                {...register('lastName')}
                error={!!errors.lastName}
                helperText={errors.lastName ? errors.lastName?.message : ''}
                disabled={props?.from === 'admin'}
                inputProps={{
                  maxLength: 255,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor='PGpatientRelation'>
                {props?.from !== 'admin' ? 'Relationship to the patient*' : 'Relationship to the patient'}
              </InputLabel>
              <TextField
                id='PGpatientRelation'
                autoComplete='PGpatientRelation'
                placeholder='Select Relation to the parent'
                select // tell TextField to render select
                required
                fullWidth
                {...register('relation')}
                error={!!errors.relation}
                helperText={errors.relation ? errors.relation?.message?.toString() : ''}
                value={watch('relation') ? watch('relation') : 'select_relation_to_the_child'}
                disabled={props?.from === 'admin'}
              >
                <MenuItem key={-1} value={'select_relation_to_the_child'} disabled>
                  Select relation to the child
                </MenuItem>
                {GaurdianRelation.map((option, key) => (
                  <MenuItem key={key} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor='PGphoneNo'>{props?.from !== 'admin' ? 'Phone Number*' : 'Phone Number'}</InputLabel>
              <ReactPhoneInput
                component={TextField}
                label=''
                onlyCountries={['us']}
                country={'us'}
                masks={{ us: '(...) ...-....' }}
                autoFormat={true}
                disableDropdown={true}
                defaultErrorMessage={'Please enter a phone number'}
                {...register('mobileNumber')}
                inputProps={{
                  helperText: errors.mobileNumber ? errors.mobileNumber?.message : '',
                  error: !!errors.mobileNumber,                  
                }}
                countryCodeEditable={false}
                onChange={(e) => {
                  setValue('mobileNumber', e, { shouldValidate: true, shouldDirty: true });
                }}
                value={watch('mobileNumber') ? watch('mobileNumber') : null}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor='PGemailAddress'>
                {props?.from !== 'admin' ? 'Email Address*' : 'Email Address'}
              </InputLabel>
              <TextField
                id='PGemailAddress'
                autoComplete='PGemailAddress'
                placeholder='Enter email address'
                required
                fullWidth
                {...register('email')}
                error={!!errors.email}
                helperText={errors.email ? errors.email?.message?.toString() : ''}
                disabled
                inputProps={{
                  maxLength: 255,
                }}
              />
            </Grid>
          </Grid>

          <Box
            sx={{
              marginTop: 2,
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <InputLabel htmlFor='priary-gaurdian' sx={{ display: 'flex', alignItems: 'center', fontSize: 13 }}>
                  <b>Is there any active custody or parenting agreement regarding the patient </b>
                  <Tooltip
                    ref={tooltipRef}
                    open={open}
                    title={tooltipContent}
                    arrow
                    placement='top'
                    enterTouchDelay={0}
                  >
                    <ErrorOutlineOutlinedIcon onMouseEnter={() => setOpen(true)} fontSize='small' />
                  </Tooltip>
                </InputLabel>
                <FormControl error={!!errors.isActiveCustody}>
                  <RadioGroup
                    row
                    onChange={handlePGActiveParenting}
                    name='PGactiveParenting'
                    value={watch('isActiveCustody') ? watch('isActiveCustody') : null}
                  >
                    <FormControlLabel
                      value='yes'
                      control={<Radio />}
                      label='Yes'
                      {...register('isActiveCustody')}
                      disabled={props?.from === 'admin'}
                    />
                    <FormControlLabel
                      value='no'
                      control={<Radio />}
                      label='No'
                      {...register('isActiveCustody')}
                      disabled={props?.from === 'admin'}
                    />
                  </RadioGroup>
                  {agreementMessage ? (
                    <Alert severity='info'>
                      Please email a copy of the agreement to newpatient@mindweal.com, including the patient&apos;s name
                      and any relevant details. Use &lsquo;Parental Agreement - [Patient&apos;s Name]&lsquo; as the
                      subject line. Thank you !
                    </Alert>
                  ) : null}
                  <FormHelperText>
                    {errors.isActiveCustody ? errors.isActiveCustody?.message?.toString() : ''}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <InputLabel htmlFor='secondary-gaurdian' sx={{ display: 'flex', alignItems: 'center', fontSize: 13 }}>
                  <b>Do you wish to make Primary Guardian as an Emergency Contact person?</b>

                  <Tooltip
                    ref={emergencyContactTooltipRef}
                    open={tooltipOpen}
                    title={emergencyContactTooltip}
                    arrow
                    placement='bottom'
                    enterTouchDelay={0}
                  >
                    <ErrorOutlineOutlinedIcon onMouseEnter={() => setTooltipOpen(true)} fontSize='small' />
                  </Tooltip>
                
                </InputLabel>

                <FormControl error={!!errors.isEmergency}>
                  <RadioGroup
                    row
                    name='isEmergency'
                    value={watch('isEmergency') === 'yes' ? 'yes' : watch('isEmergency') === 'no' ? 'no' : ''}
                  >
                    <FormControlLabel
                      value='yes'
                      control={<Radio />}
                      label='Yes'
                      {...register('isEmergency')}
                      disabled={props?.from === 'admin'}
                    />
                    <FormControlLabel
                      value='no'
                      control={<Radio />}
                      label='No'
                      {...register('isEmergency')}
                      disabled={props?.from === 'admin'}
                    />
                  </RadioGroup>
                  <FormHelperText>{errors.isEmergency ? errors.isEmergency?.message : ''}</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          <Restricted permission={permissions.EDIT_PROFILE}>
            <Grid container justifyContent='flex-end'>
              <Grid item>
                <LoadingButton
                  loading={isProfileDetailsFetched}
                  variant='contained'
                  // loadingPosition='start'
                  type='submit'
                  fullWidth
                  sx={{ mt: 2, mb: 2 }}
                >
                  Save
                </LoadingButton>
              </Grid>
            </Grid>
            <Typography variant='body1' gutterBottom sx={{ backgroundColor: '#eef2ff', padding: '8px 8px 4px 8px' }}>
              <b>Note:</b> Any updates to demographic information on one sibling account will 
              automatically apply to all linked sibling accounts.
            </Typography>
          </Restricted>
        </Box>
      </Box>
    </Container>
  );
};

export default PrimaryGaurdian;
