import { useState, useEffect } from 'react';
import { Container, Grid, Typography } from '@mui/material';
import { DataGrid, GridColDef,getGridDateOperators,gridClasses } from '@mui/x-data-grid';
import dayjs from 'dayjs';

import { useAppDispatch } from 'store/hooks';
import {getAllRequestsForAdmin} from 'store/slices/accountManagementSlice';
import COLORS from 'theme/colors';
import { useNavigate } from 'react-router-dom';
import { ROUTE } from 'route/RouteEnums';

const AccountManagementRequest = () => {

  const [requests, setRequests] = useState<any[] | null>([]);
  
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
 
  const getRequestList = async () => { 
    const requestDetails:any=[];   
    await dispatch(getAllRequestsForAdmin({}))
      .unwrap()
      .then((response: any) => {
        if (response?.status === 200) {
        

          if(response?.data?.length){
            // eslint-disable-next-line array-callback-return
            response?.data?.map((d:any) =>{
              const data = {
                id:d?.id,
                ticketId:d?.ticketId,
                patientId: d?.patientId,
                patientName: d?.patientFirstName?.charAt(0).toUpperCase()+d?.patientFirstName?.slice(1) + ' ' + 
                d?.patientLastName?.charAt(0).toUpperCase()+d?.patientLastName?.slice(1),
                guardianName: d?.guardianFirstName?.charAt(0).toUpperCase()+d?.guardianFirstName?.slice(1) + ' ' +
                d?.guardianLastName?.charAt(0).toUpperCase()+d?.guardianLastName?.slice(1),
                type: d?.ticketType,
                requestDate : d?.dateOfRequest,
                requestType: d?.requestType,
                status: d?.status,
              };
            
              requestDetails.push(data); 
              
              setRequests(requestDetails);
            });
          }else {
            setLoading(false);
          }
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    getRequestList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
 
  const columns: GridColDef[] = [ 
    {
      field: 'patientName',
      headerName: 'Patient Name',
      flex: 1.5,
    },
    {
      field: 'guardianName',
      headerName: 'Guardian Name',
      flex: 1.5,
    },
  
    {
      field: 'requestType',
      headerName: 'Request Type',
      flex: 1.5,
      renderCell:(params:any)=>{        
        return(<>
          {params?.row?.requestType}
        </>);
      }
    },
    {
      field: 'requestDate',
      headerName: 'Date of Request',
      flex: 1,
      type:'date',
      filterOperators: getGridDateOperators().filter((operator) => operator.value === 'isNotEmpty' || 
      operator.value === 'isEmpty' || operator.value === 'onOrBefore' || operator.value === 'onOrAfter' || 
      operator.value === 'after' || operator.value === 'not' || operator.value === 'is'),
      valueFormatter: (params:any) => dayjs(params?.row?.startDate).format('MM/DD/YYYY'),
      valueGetter:(params:any) => dayjs(params?.row?.startDate).format('MM/DD/YYYY'),
      renderCell: (params: any) => {
        return (
          <>
            {dayjs(params?.row?.startDate).format('MM/DD/YYYY')}
          </>
        );
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1.5,
      renderCell:(params:any)=>{        
        return(
          <>
            <Typography 
              sx={{
                background: params?.row?.status === 'Rejected' || params?.row?.status === 'Transfer Failed' ? '#FFFBFA':
                  params?.row?.status === 'Approved' || params?.row?.status === 'Transfer Successful' ? '#F6FEF9' : 
                    '#F5F8FF', 
               
                color: params?.row?.status === 'Rejected' || params?.row?.status === 'Transfer Failed' ? '#B42318' :
                  params?.row?.status === 'Approved' || params?.row?.status === 'Transfer Successful' ? '#027A48' :
                    '#1570EF', 
                border: params?.row?.status ==='Rejected'||params?.row?.status==='Transfer Failed'?'1px solid #B42318': 
                  params?.row?.status === 'Approved' || params?.row?.status==='Transfer Successful'?'1px solid #027A48':
                    '1px solid #1570EF', 
                borderRadius: '12px', padding: '2px 10px 0px 10px',
              }}> 
              {params?.row?.status}
            </Typography>
          </>
        );
      }
    },
  ];

  const handleRowClick=(params:any) =>{      
    if (params.row.requestType === 'Change Email' || params.row.requestType === 'Change Phone Number' ||
       params.row.requestType === 'Change Email & Phone Number') {
      navigate(ROUTE.ADMIN_UPDATE_EMAIL_PHONE, {state: {ticketId:params.row.ticketId, status:params.row.status}});
    } else if(params.row.requestType === 'Transfer Ownership'){
      // eslint-disable-next-line max-len
      navigate(ROUTE.ADMIN_TRANSFER_ACCOUNT_OWNERSHIP,{state: {ticketId:params.row.ticketId, status:params.row.status}});
    } else if(params.row.requestType === 'Deactivate Account'){
      navigate(ROUTE.ADMIN_DEACTIVATE_ACCOUNT, {state: {ticketId:params.row.ticketId, status:params.row.status}});
    }
  };

  return (
    <Container maxWidth='lg'>
      <Grid item xs={12}>
        <Typography
          variant='h5'
          position={'relative'}
          width={'fit-content'}
          sx={{ paddingTop: 6, textTransform: 'capitalize' }}
        >
          Account Management Requests
        </Typography>
      </Grid>
  

      <Grid item xs={12} sx={{ backgroundColor: COLORS.White }}>  
        <DataGrid
          autoHeight
          loading={loading}
          rows={requests ? requests : []}
          columns={columns}
          getRowId={(row) => row.id}
          onRowClick={handleRowClick}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10, page: 0 },
            },
            sorting:{
              sortModel:[{field:'id', sort:'desc'}]
            }
          }}
          
          pageSizeOptions={[10, 25, 50]}
          rowSpacingType='margin'
          getRowClassName={(params: any) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
          style={{ cursor: 'pointer' }}
          sx={{
            border: 'none',
            backgroundColor: COLORS.LightYellow,
            fontSize: 14,
            fontWeight: 400,
            '.MuiDataGrid-toolbarContainer': {
              background: '#FFFBF1',
            },
            '.MuiDataGrid-columnHeaders': {
              marginBottom: 2,
              boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.16)',
            },
            '.MuiDataGrid-columnHeadersInner': {
              background: COLORS.White,
            },
            '.MuiDataGrid-columnHeaderTitle': {
              fontWeight: 600,
            },
            [`& .${gridClasses.row}.even`]: {
              backgroundColor: COLORS.White,
              '&:hover, &.Mui-hovered': {
                backgroundColor: COLORS.LightBlue,  
              },
            },
            [`& .${gridClasses.row}.odd`]: {
              backgroundColor: COLORS.LightGray,
              '&:hover, &.Mui-hovered': {
                backgroundColor: COLORS.LightBlue,
              },
            },
            '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
              outline: 'none !important',
            },
            '&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 400,
              fontSize: '16px',
              color: '#000000',
              lineHeight: '24px'
            },
          }}
          localeText={{ noRowsLabel: 'No requests are available' }}
          
        /> 
      </Grid>
    </Container>
  );
};
export default AccountManagementRequest;