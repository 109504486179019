import { useState, useEffect, useContext } from 'react';
import { Grid, Typography, Container, CardMedia, Box } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { sanitize } from 'dompurify';
import { useAppDispatch } from 'store/hooks';
import { getPostMeetingDetails, getPostMeetingSummary } from 'store/slices/patientInfoSlice';
import { Page } from 'components/Page';
import { ROUTE } from 'route/RouteEnums';
import { AuthContext } from 'utils/AuthContext';
import COLORS from 'theme/colors';

const Treatment = (props: any) => {

  const [treatmentPlan, setTreatmentPlan] = useState<string>();
  const [medications, setMedications] = useState<any>([]);
  const [diagnoses, setDiagnoses] = useState<any>();
  const [labs, setLabs] = useState<any>();
  const dispatch = useAppDispatch();
  const { user } = useContext(AuthContext);

  const columns: GridColDef[] = [
    {
      field: 'medication',
      headerName: 'Medication',
      flex: 2,
      sortable: false
    },
    {
      field: 'sig',
      headerName: 'Dosage',
      flex: 2,
      sortable: false
    }
  ];

  const diagnosisColumns: GridColDef[] = [
    {
      field: 'diagnosis',
      headerName: 'Active Diagnoses',
      flex: 2,
      sortable: false
    },
  ];
  useEffect(() => {
    dispatch(getPostMeetingDetails(user?.patientId))
      .unwrap()
      .then(async (response: any) => {
        if (response?.status === 200 && response?.data !== '') {          
          const diagnosisData = await JSON.parse(response?.data?.diagnosis);
          const diagnosis = diagnosisData?.map((d: any, i: number) => {
            return (
              {
                id: i,
                diagnosis: d.diagnosis,
                // diagnosedDate: d.diagnosedDate
              }
            );
          });
          setDiagnoses(diagnosis);
          const data = await JSON.parse(response?.data?.medications);
          const medication = data !== null && data?.map((d: any, i: number) => {
            return (
              {
                id: i,
                medication: d.medication,
                sig: d.sig
              }
            );
          });
          setMedications(medication);
        }else{
          setDiagnoses([]);
          setMedications([]);
        }
      });

    dispatch(getPostMeetingSummary(user?.patientId))
      .unwrap()
      .then((response: any) => {
        if (response?.status === 200 && response?.data !== '') {
          setLabs(response?.data);
          setTreatmentPlan(response?.data?.medicationTreatmentPlan);
        }else{
          setTreatmentPlan(undefined);
          setLabs([]);
        }
      });
  }, [dispatch, user?.patientId]);

  return (
    <Page backDir={ROUTE.DASHBOARD}>
      <Container component='main' maxWidth='md'>

        <Grid>
          <Grid item sx={{ textAlign: 'center' }} alignItems={'center'}>

            <Box pt='16px' display={'flex'} alignItems={'center'} flexDirection={'column'}>

              <CardMedia
                component={'img'}
                src='../images/treatmentPlan.png'
                alt='dashboard'
                sx={{ width: 70, height: 'auto' }}
              />
              <Typography
                variant='h5'
                position={'relative'}
                width='100%'
                sx={{ textTransform: 'capitalize', textAlign: 'center' }}
              >
                Treatment Plan
                <CardMedia
                  component={'img'}
                  src='../images/vector.svg'
                  alt='heading divider'
                  sx={{
                    position: 'absolute', left: { xs: '31%', sm: '41%', md: '36%' },
                    width: { xs: 70, sm: 80, md: 160 }, height: 'auto'
                  }}
                />
              </Typography>
            </Box>
          </Grid>
        </Grid>


        <Grid>
          <Box width='100%'
            sx={{
              background: '#FFF',
              display: 'flex',
              padding: '30px',
              margin: '30px 0',
              borderRadius: '24px',
              border: '1px solid #F2F4F7',
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'stretch'
            }}>
            <Grid container>

              {treatmentPlan &&
                <Grid item xs={12}>
                  <Typography
                    sx={{ fontSize: '20px', fontFamily: 'Playfair Display', fontWeight: '500', color: '#1C1F24' }}>
                    Medication Management
                  </Typography>
                  <Typography variant={'body1'} mt={4} sx={{ textAlign: 'justify', color: '#1C1F24' }}>
                    {treatmentPlan}
                  </Typography>
                </Grid>}

              <Grid item xs={12}>
                <Typography mt={4} mb={4}
                  sx={{ fontSize: '20px', fontFamily: 'Playfair Display', fontWeight: '500', color: '#1C1F24' }}
                > Current Medications </Typography>


                <DataGrid
                  rows={medications}
                  columns={columns}
                  hideFooter
                  getRowHeight={() => 'auto'}
                  disableColumnMenu
                  disableColumnFilter
                  disableColumnSelector
                  autoHeight
                  localeText={{ noRowsLabel: 'No medications described yet.' }}
                  sx={{

                    '.MuiDataGrid-columnSeparator': {
                      display: 'none',
                    },
                    '.MuiDataGrid-columnHeaders': {
                      background: '#F2F4F7',
                      boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.16)',
                      fontSize: '16px'
                    },
                    '.MuiDataGrid-cell': {
                      fontSize: '16px',
                      padding:'10px'
                    }
                  }}
                />
              </Grid>

              {diagnoses &&
                <Grid item xs={12} mt={4}>
                  <Typography
                    sx={{ fontSize: '20px', fontFamily: 'Playfair Display', fontWeight: '500', color: '#1C1F24' }}>
                    Diagnoses
                  </Typography>
                  <Typography variant={'body1'} mt={2} sx={{ textAlign: 'justify', color: '#1C1F24' }}>
                    <DataGrid
                      rows={diagnoses}
                      columns={diagnosisColumns}
                      getRowHeight={() => 'auto'}
                      hideFooter
                      disableColumnMenu
                      disableColumnFilter
                      disableColumnSelector
                      autoHeight
                      localeText={{ noRowsLabel: 'No diagnosis.' }}
                      sx={{

                        '.MuiDataGrid-columnSeparator': {
                          display: 'none',
                        },
                        '.MuiDataGrid-columnHeaders': {
                          background: '#F2F4F7',
                          boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.16)',
                          fontSize: '16px'
                        },
                        '.MuiDataGrid-cell': {
                          fontSize: '16px',
                          padding:'10px'
                        }
                      }}
                    />
                  </Typography>
                </Grid>}

              {labs &&
                <Grid item xs={12} mt={4}>
                  <Typography
                    sx={{ fontSize: '20px', fontFamily: 'Playfair Display', 
                      fontWeight: '500', color: '#1C1F24', mb: 2
                    }}>
                    Labs
                  </Typography>
                  <Grid container>
                    <Grid item xs={12}>
                      <Grid container
                        sx={{
                          border: '1px solid',
                          borderColor: COLORS.BorderGray,
                        }}>
                        <Grid item xs={12} sm={3} px={1} py={1} sx={{ background: '#F2F4F7' }}>
                          <Typography variant='h4'>
                            Labs Ordered
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={9} px={2} py={1}>
                          <Typography variant='h4'>
                            {labs?.labsOrdered?.replaceAll(',', ', ')}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container
                        sx={{
                          border: '1px solid',
                          borderColor: COLORS.BorderGray,
                        }}>
                        <Grid item xs={12} sm={3} px={2} py={1} sx={{ background: '#F2F4F7' }}>
                          <Typography variant='h4'>
                            Instructions
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={9} px={2} py={1}>
                          <Typography variant='h4'>
                            {labs?.labInstructions?.replaceAll(',', ', ')}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container
                        sx={{
                          border: '1px solid',
                          borderColor: COLORS.BorderGray,
                        }}>
                        <Grid item xs={12} sm={3} px={2} py={1} sx={{ background: '#F2F4F7' }}>
                          <Typography variant='h4'>
                            Where and How
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={9} px={2} py={1}>
                          <Typography variant='h4'>
                            <div dangerouslySetInnerHTML=
                              {{ __html: sanitize(labs?.labswhereAndHow?.replaceAll(',', ', ',),
                                {ADD_ATTR:['target']}) }} />
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>}
            </Grid>
          </Box>

          <Box width='100%'
            sx={{
              background: '#FFECC8',
              display: 'flex',
              padding: '30px',
              margin: '30px 0',
              borderRadius: '24px',
              border: '1px solid #F2F4F7',
              justifyContent: 'center',
              alignItems: 'center',
              alignSelf: 'stretch'
            }}>
            <Grid container>
              <Grid item xs={12} md={3}>
                <CardMedia
                  component={'img'}
                  src='../images/medicationMgmt.svg'
                  alt='card-img'
                  sx={{ width: 170, height: 'auto', position: 'relative' }}
                />
              </Grid>
              <Grid item xs={12} md={9}>
                <Typography sx={{
                  fontSize: '30px', fontWeight: 600, lineHeight: '55px', color: '#1C1F24',
                  fontFamily: 'Playfair Display,serif'
                }}>
                  Medication Management</Typography>
                <CardMedia
                  component={'img'}
                  src='../images/medicationVector.svg'
                  alt='heading divider'
                  sx={{ width: 170, height: 'auto' }}
                />
                <Typography 
                  sx={{ fontSize: '16px', fontWeight: 400, lineHeight: '30px', mt: '22px', color: '#1C1F24' }}>
                  With clinical expertise and precision tooling, we tailor the right medication
                  that works best for your child.
                </Typography>
              </Grid>
            </Grid>
          </Box>

        </Grid>

      </Container>
    </Page>
  );
};

export default Treatment;
