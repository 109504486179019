import * as yup from 'yup';

const stringValidationWithRequired = (message: string) => yup.string().required(message).trim();

const stringValidationWithRequiredForName = (message: string) =>
  yup
    .string()
    .required(message)
    .trim('Do not start or end  with Space')
    .matches(
      /^([A-Za-z][A-Za-z0-9'-. ]*)+$/,
      'Must be start with Alphabet and You can use Digits, Apostrophe, Dash And Dot'
    );

const email = yup
  .string()
  .email('Please Enter Valid Email Address')
  .required('Please Enter Your Email')
  .trim()
  // eslint-disable-next-line max-len, no-useless-escape
  .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    'Please Enter Valid Email Address');

const mobileNumber = yup
  .string()
  .required('Please Enter Phone Number')
  .trim()
  .matches(/^[0-9]+$/, 'Phone Number must be in Digits')
  .length(11, 'Phone Number must be exact 10 Digits.');
const password = yup
  .string()
  .required('Please Enter Password')
  .trim()
  .matches(
    /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/,
    'Please choose a stronger password to protect your account and sensitive health information.'
  );
const confirmPassword = yup
  .string()
  .required('Please Enter Confirm Password')
  .trim()
  .oneOf([yup.ref('password')], 'Password and Confirm Password should match');
const notification = yup.boolean().oneOf([true], '');
const zipcode = yup
  .string()
  .required('Please Enter Zipcode')
  .trim()
  .matches(/^[0-9]+$/, 'Zipcode must be in Digits')
  .length(5);

const lengthCheck = /^[A-Za-z\d@$!%*}.<,>?/;:'"+=_)(^#`~|\\{?&-[\]]{8,}/;
const lowerCaseCheck = /^(?=.*[a-z])/;
const UpperCaseCheck = /^(?=.*[A-Z])/;
const digitCheck = /^(?=.*[0-9])/;
const specialCharCheck = /[!@#$%&*;:',.></_+=}[|\\)(\]{?]/;
const expirydate = yup
  .string()
  .required('Expiry Date is Required')
  .matches(/^(([0-9]{4})|[0-9]{2})-?(0[1-9]|1[0-2]$)/, 'Invalid Expiry Date')
  .test('expiryDate', 'Card has Expired', function (value) {
    if (!value) return false;

    // Extracting month and year from the expiry date
    const [enteredMonth, enteredYear] = value.split('/');

    // Creating a Date object for the current month and year
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Month is zero-based
    const currentYear = currentDate.getFullYear();

    // Comparing the expiry date with the current date
    if (+enteredYear < currentYear || (+enteredYear === currentYear && +enteredMonth < currentMonth)) {
      return false; // Card has expired
    }

    return true; // Card is valid
  });

export const checkPasswordCriteria = (value: any) => {
  const errors = [
    {
      name: 'lengthCheck',
      schema: lengthCheck,
      message: '8 characters minimum',
    },
    {
      name: 'lowerCaseCheck',
      schema: lowerCaseCheck,
      message: '1 lowercase characters',
    },
    {
      name: 'upperCaseCheck',
      schema: UpperCaseCheck,
      message: '1 uppercase character',
    },
    {
      name: 'specialCharCheck',
      schema: specialCharCheck,
      message: '1 special character',
    },
    { name: 'digitCheck', schema: digitCheck, message: '1 number' },
  ];
  return errors.map((condition) => {
    return {
      isValid: condition.schema.test(value),
      message: condition.message,
      name: condition.name,
    };
  });
};

export const singupSchema = yup
  .object()
  .shape({
    firstName: stringValidationWithRequiredForName('Please Enter First Name'),
    lastName: stringValidationWithRequiredForName('Please Enter Last Name'),
    email: email,
    mobileNumber: mobileNumber,
    password: password,
    confirmPassword: confirmPassword,
    enableNotification: notification,
    enableSMSNotification: notification,
    referral: yup.string(),
  })
  .strict();

export const transferSingupSchema = yup
  .object()
  .shape({
    firstName: yup.string().optional(),
    lastName: yup.string().optional(),
    email: email,
    mobileNumber: mobileNumber,
    password: password,
    confirmPassword: confirmPassword,
    enableNotification: notification,
    enableSMSNotification: notification,
    referral: yup.string(),
  })
  .strict();

export const loginSchema = yup
  .object()
  .shape({
    email: email,
    password: stringValidationWithRequired('Please Enter Password'),
  })
  .strict();

export const forgotPasswordSchema = yup
  .object()
  .shape({
    email: email,
  })
  .strict();

export const resetPasswordSchema = yup
  .object()
  .shape({
    email: email,
    password: password,
    confirmPassword: confirmPassword,
  })
  .strict();

export const verifyForgotPasswordLink = yup.object().shape({
  token: yup.string(),
});

export const querySchema = yup.object().shape({
  message: stringValidationWithRequired('Please Enter Your Queries'),
});

export const zipCodeSchema = yup
  .object()
  .shape({
    zipCode: zipcode,
  })
  .strict();

export const providerDetailsSchema = yup.object().shape({
  officeName: stringValidationWithRequired('Please Enter Office Name'),
  firstName: stringValidationWithRequiredForName('Please Enter First Name'),
  lastName: stringValidationWithRequiredForName('Please Enter Last Name'),
  officePhoneNumber: mobileNumber,
  officeFaxNumber: yup
    .string()
    .matches(/^[0-9]+$/, 'Phone Number must be In Digits')
    .optional()
    .test({
      name: 'max',
      message: 'Office Fax Number must be 10 Digits or Empty',
      test: (value) => value === '1' || value === '' || value?.length === 11 || value === undefined || value === null,
    }),
}).strict();
export const childInfoSchema = yup
  .object()
  .shape({
    id: yup.number(),
    firstName: stringValidationWithRequiredForName('Please Enter Patient\'s First Name'),
    lastName: stringValidationWithRequiredForName('Please Enter Patient\'s Last Name'),
    preferredName: stringValidationWithRequiredForName('Please Enter Patient\'s Preferred Name'),
    dateOfBirth: yup.string(),
    biologicalGender: stringValidationWithRequired('Please select Patient\'s Biological Gender'),
    preferredGender: yup.string().optional(),
    childAddress: yup.string().required('Please Enter Patient\'s Address'),
    zipcode: yup.string().required('Please enter valid Zip Code ').matches(/^[0-9]+$/, 'Zip Code must be in Digits')
      .test({
        name: 'max',
        message: 'Zip Code must be 5 Digits',
        test: (value) => value === '1' || value === '' || value?.length === 5 || value === undefined || value === null,
      }),
    city: yup.string(),
    state: yup.string(),
    preferredLocation: yup.string().required('Please Select Preferred Location ')
  })
  .strict();

export const pharmacyDetailsSchema = yup
  .object()
  .shape({
    pharmacyName: stringValidationWithRequiredForName('Please Enter Pharmacy Name'),
    phoneNumber: mobileNumber,
    address: yup
      .string()
      .required('Please enter Pharmacy Address')
      .trim('Do not start or end  with Space'),
    zipCode: yup.string().matches(/^[0-9]{5}$/i, 'Zip Code must be in Digits'),
    accessMedicationHistory: yup.string().required('Please select option'),
  })
  .strict();

export const insuranceDetailsSchema = yup
  .object()
  .shape({
    insuranceCarrier: yup.string().required(),
    planId: stringValidationWithRequired('Please Enter Member Id'),
    groupNo: stringValidationWithRequired('Please Enter Group Number'),
    subscriberName: stringValidationWithRequiredForName('Please Enter Name Of Subscriber'),
    subscriberDob: stringValidationWithRequired('Please Select Date Of Subscriber Birthday'),
    patientRelation: stringValidationWithRequired('Please Select Patient Relation'),
    cardImage: yup.string(),
  })
  .strict();

export const creditCardSchema = yup.object().shape({
  cardno: yup.string().max(16).required('Please Enter Credit Card Number'),
  cardholdername: stringValidationWithRequiredForName('Please Enter Card Holder Name'),
  expirydate: expirydate,
  cvv: yup
    .string()
    .required('CVV Number is Required')
    .matches(/^[0-9]{3,4}$/, 'Invalid CVV Number'),
});

export const guardianSchema = yup
  .object()
  .shape({
    id: yup.number(),
    firstName: stringValidationWithRequiredForName('Please Enter First Name'),
    lastName: stringValidationWithRequiredForName('Please Enter Last Name'),
    email: email,
    mobileNumber: mobileNumber,
    relation: yup
      .string()
      .oneOf(['Father', 'Mother', 'Guardian', ''])
      .required('Please Select your Relation to the Patient'),
    isEmergency: yup.string().required(),
    guardianCategory: yup.string(),
    isActiveCustody: yup.string(),
    other: yup.string().when('relation', ([relation], schema): any => {
      return relation === 'other' ? schema.required('Please Enter Add Details') : schema;
    }),
  })
  .strict();

const medicationSchema = yup.object().shape({
  medication: yup.string(),
  // dispense: yup.string().matches(digitCheck, 'Please enter digits only').required('Please enter dispense'),
  // rxnorm: yup.string().required('Please enter Rxnorm'),
  sig: yup.string(),
});

const diagnosisSchema = yup.object().shape({
  diagnosis: yup.string(),
  diagnosedDate: yup.string(),
});

export const afterMeetingSchema = yup.object().shape({
  treatmentPlan: yup.string().required('Please Select Treatment Plan'),
  pendingBalance: yup.string(),
  others: yup.string().optional(),
  medications: yup.array().of(medicationSchema),
  diagnosis: yup.array().of(diagnosisSchema),
  referrals: yup.string(),
  ratingScales: yup.string(),
  roiForms: yup.string(),
  followup: yup.string(),
});

export const detailsSchema = yup.object().shape({
  chartId: yup
    .string()
    .trim()
    .matches(/^[A-Za-z0-9]+$/, 'Looks like invalid id'),
  pricingPlan: yup.string(),
  creditCardAvailable: yup.string(),
  creditCardStatus: yup.string(),
  billingStatus: yup.string(),
  insCardStatus: yup.string(),
  coPayAmount: yup.string()
    .matches(/^[0-9]*[.]{0,1}[0-9]*$/,
      'Please enter Amount in digits. Special characters are not allowed, except for a decimal point.')
    .test('is-decimal', 'Amount must be less than 5000 & greater than 0.50', (value) => {
      if (!value) return true;
      else if (parseInt(value) > 5000 || parseFloat(value) < 0.5) return false;
      return !isNaN(parseFloat(value));
    }),
});

export const upcomingAppointmentSchema = yup.object().shape({
  scheduledDateTime: yup.string(),
});

export const mcatIScoreSchema = yup.object().shape({
  mcatIScore: yup
    .string()
    .trim()
    .required('Please Enter MCAT-i Score')
    .matches(/^[0-9]*[.]{0,1}[0-9]*$/, 'MCAT-i Score must be in Digits and Only One Dot can Used'),
  updatedOn: yup.string(),
});
export const appointmentSchema = yup
  .object()
  .shape({
    preferredTime: yup
      .object()
      .shape({
        morning: yup.array().of(yup.string()),
        afternoon: yup.array().of(yup.string()),
        evening: yup.array().of(yup.string()),
      })
      .required('Please Select Preferred Time.'),
    contactMethod: yup.string().required('Field Must Be Checked'),
    mobileNumber: mobileNumber,
    consultationType: yup.string().required('Field Must Be Checked'),
    remarks: yup.string(),
  })
  .strict();

export const secondaryGuardianSchema = yup
  .object()
  .shape({
    id: yup.number(),
    firstName: yup.string(),
    lastName: yup.string(),
    email: yup.string().email('Invalid Email Format'),
    mobileNumber: yup
      .string()
      .matches(/^[0-9]+$/, 'Phone Number Must Be In Digits')
      .optional()
      .test({
        name: 'max',
        message: 'Phone Number Must Be 10 Digits',
        test: (value) => value === '1' || value === '' || value?.length === 11 || value === undefined || value === null,
      }),
    relation: yup.string(),
    isEmergency: yup.string(),
    guardianCategory: yup.string(),
    isActiveCustody: yup.string(),
    other: yup.string().nullable().when('relation', ([relation], schema): any => {
      return relation === 'other' ? schema.required('Please enter Details') : schema;
    }),
  })
  .strict();

export const emargencyGuardianSchema = yup
  .object()
  .shape({
    id: yup.number(),
    firstName: stringValidationWithRequiredForName('Please enter First Name'),
    lastName: stringValidationWithRequiredForName('Please enter Last Name'),
    email: yup.string()
      .email('Please Enter Valid Email Address')
      .optional(),
    mobileNumber: yup.string(),
    relation: yup.string().required('Please enter Relationship to the Patient'),
    isEmergency: yup.string().required(),
    guardianCategory: yup.string(),
    isActiveCustody: yup.string(),
    other: yup.string().when('relation', ([relation], schema): any => {
      return relation === 'other' ? schema.required('Please enter Add Details') : schema;
    }),
  })
  .strict();

export const vitalSchema = yup.object().shape({
  height: yup.string()
    .matches(/^[0-9]*[.]{0,1}[0-9]*$/,
      'Please enter Height in digits. Special characters are not allowed, except for a decimal point.'
    )
    .test('is-decimal', 'Height Must Be a Number', (value) => {
      if (!value) return true;
      return !isNaN(parseFloat(value));
    }),
  weight: yup.string()
    .matches(/^[0-9]*[.]{0,1}[0-9]*$/,
      'Please enter Weight in digits. Special characters are not allowed, except for a decimal point.')
    .test('is-decimal', 'Weight Must Be a Number', (value) => {
      if (!value) return true;
      return !isNaN(parseFloat(value));
    }),
  bloodPressure1: yup.string().test('is-number', 'Blood Pressure Must Be a Number', (value) => {
    if (!value) return true;
    return !isNaN(parseFloat(value));
  }),
  bloodPressure2: yup.string().test('is-number', 'Blood Pressure Must Be a Number', (value) => {
    if (!value) return true;
    return !isNaN(parseFloat(value));
  }),
});

export const taskSchema = yup.object().shape({
  taskTitle: yup.string().required('Please enter Task Title'),
  assignedTo: yup.string().required('Please select VMA '),
  category: yup.string(),
  status: yup.string().required('Please select status'),
  dueDate: yup.string(),
  priority: yup.string().required('Please select priority'),
  patientName: yup.string(),
  notes: yup.string()
});

export const chargePaymentSchema = yup.object().shape({
  amount: yup.string()
    .matches(/^[0-9]*[.]{0,1}[0-9]*$/,
      'Please enter Amount in digits. Special characters are not allowed, except for a decimal point.')
    .test('is-decimal', 'Amount must be less than 5000 & greater than 0.50', (value) => {
      if (!value) return true;
      else if (parseInt(value) > 5000 || parseFloat(value) < 0.5) return false;
      return !isNaN(parseFloat(value));
    }),
  description: yup.string().required('Please enter Description.')
});

export const changePasswordSchema = yup.object().shape({
  password: password,
  confirmPassword: confirmPassword,
});