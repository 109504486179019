import React, { useContext, useEffect, useState } from 'react';
import {
  CardMedia,
  Grid,
  Typography,
  CircularProgress,
  Button,
  Box,
  Dialog,
  DialogContent,
  Tooltip,
  styled,
  CardProps,
  Card,
  CardContent,
  TooltipProps,
  tooltipClasses,
  Alert,
  DialogActions,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useNavigate } from 'react-router-dom';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

import { ROUTE } from 'route/RouteEnums';
import { useAppDispatch } from 'store/hooks';
import { getScheduleAppointmentDetails, getAppointmentConfirmationDetails, 
  getCheckInDetails, createPatient, getAddCardLink, payAmount } from 'store/slices/appointmentSlice';
import {getDrChronoChartDetails} from 'store/slices/patientInfoSlice';  
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import COLORS from 'theme/colors';
import { Stack } from '@mui/system';
import { AuthContext } from 'utils/AuthContext';
import { getVitalsDetails } from 'store/slices/vitalSlice';
import { getWellbeingStatus } from 'store/slices/mcatSlice';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import { toast } from 'react-toastify';

const AppointmentStatus = (props: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [status, setStatus] = useState<string>('');
  const [appointmentDetails, setAppointmentDetails] = useState<any>();
  const [displayModal, setDisplayModal] = useState<boolean>(false);
  const [systemVitals, setSystemVitals] = useState<boolean>(false);
  const [wellbeingStatus, setWellbeingStatus] = useState<any>();
  const [upcomingAppointmentPreparation, setUpcomingAppointmentPreparation] = useState<boolean>();
  const [appointmentStatus, setAppointmentStatus] = useState<any>();
  const [showCheckIn, setShowCheckIn] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(true);
  const [checkIn, setCheckIn] = useState<boolean>(false);
  const [undoTime, setUndoTime] = useState<number>(0);
  const [hideBorder,setHideBorder] = useState<boolean>(false);
  const [disableJoinNowButton, setDisableJoinNowButton] = useState<boolean>(true);
  const [drChronoStatus, setDrChronoStatus] = useState<string>();
  const [showLoadingMessage, setShowLoadingMessage] = useState<boolean>(false);
  const [showSyncError, setShowSyncError] = useState<boolean>(false);
  const [showDelayMessage, setShowDelayMessage] = useState<boolean>(false);
  const [disableMeetingButton, setDisableMeetingButton] = useState(false);
  const [preferredLocation, setPreferredLocation] = useState<string>('');
  const [appointmentCharge, setAppointmentCharge] = useState<any>();
  const [showPaymentSuccessMessage, setShowPaymentSuccessMessage] = useState<boolean>(false);
  const [disablePayButton, setDisablePayButton] = useState<boolean>(false);

  const appointmentStatusDetails:any ={
    'Confirmed':{ 
      status:'Confirmed',
      instruction:'You have confirmed your intention to attend the upcoming scheduled appointment.',
      background:'#1570EF'
    },
    'Complete':{ 
      status:'Complete',
      instruction:'Appointment completed successfully.',
      background:'#027A48'
    },
    'Cancelled':{ 
      status:'Cancelled',
      instruction:'The appointment was cancelled either by you or by our office.',
      background:'#912018'
    },
    'Complete, no follow-up recommended':{
      status:'Complete, no follow-up recommended',
      instruction:'Appointment completed and no follow-up is recommended at this time.',
      background:'#027A48'
    },
    'No-Show':{
      status:'No-Show',
      // eslint-disable-next-line max-len
      instruction:'A "no show" is when you don\'t cancel and either miss your appointment or arrive more than 15 minutes late, which may lead to the provider choosing not to see you.',
      background:'#027A48'
    },
    'No-Show & Discharged from Practice':{ 
      status:'No-Show & Discharged from Practice',
      // eslint-disable-next-line max-len
      instruction: 'A "no show" is when you don\'t cancel and either miss your appointment or arrive more than 15 minutes late, which may lead to the provider choosing not to see you.You were discharged from the practice for frequent no-shows and late cancellations, as outlined in our policy.',
      background:'#912018'
    },
    'Late Cancel':{ 
      status:'Late Cancel',
      // eslint-disable-next-line max-len
      instruction:' Appointments canceled by you within 24 hours of the scheduled time (excluding weekends and holidays) will incur a late cancellation fee.',
      background:'#912018'
    },
    'Late Cancel & Discharged from Practice':{ 
      status:'Late Cancel & Discharged from Practice',
      // eslint-disable-next-line max-len
      instruction:'Appointments canceled by you within 24 hours of the scheduled time (excluding weekends and holidays) will incur a late cancellation fee. You were discharged from the practice for frequent no-shows and late cancellations, as outlined in our policy.',
      background:'#912018'
    },
    'Scheduled':{ 
      status:'Scheduled',
      instruction:'Your appointment is scheduled with one of our providers.',
      background:'#1570EF'
    },    
  };

  const StyleCard = styled(Card)<CardProps>(({ theme }) => ({
    boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.12)',
    border: status === 'pending' ? '1px solid #FFC44D' : '1px solid #EFF4FF',
    borderRadius: 12,
    background: status === 'pending' ? '#FFECC8' : '#FFF',
    margin: '12px 16px',
    position: 'relative',
    zIndex: 99,
    '& .MuiTypography-root': {
      marginBottom: '8px',
      position: 'relative',
    },
  }));

  
  const StyledTooltip = styled(({ className, color, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltipArrow}`]: {
      backgroundColor: '#fff!important',
      boxShadow: theme.shadows[1]
    },
    [`& .${tooltipClasses.arrow}`]: {
      '&:before': {
        border: '1px solid #fff',
        color:'#fff',              
      },      
    },
    [`& .${tooltipClasses.tooltip}`]:{
      border: '1px solid #fff',
      padding:'12px',
      borderRadius:'8px'
    }
  }));
  
  useEffect(() => {
    dispatch(getAppointmentConfirmationDetails(user?.patientId))
      .unwrap()
      .then((response: any) => {
        if (response?.status === 200) {
          setAppointmentStatus(response?.data?.status);
          setDrChronoStatus(response?.data?.status);
          setPreferredLocation(response?.data?.preferredLocation);
          setAppointmentCharge(response?.data?.appointmentCharge);
          
          const drChronoStatus = response?.data?.status;
          if (response?.data?.upcomingAppointmentDateTime !== null) {
            dayjs.extend(utc);
            const toDate = new Date(dayjs(response.data.upcomingAppointmentDateTime).add(1, 'hour').toISOString());
            const data = new Date(response.data.upcomingAppointmentDateTime);
            const monthValue = new Intl.DateTimeFormat('en-US', { month: 'long', timeZone:'America/chicago' }).format;
            const dateValue = new Intl.DateTimeFormat('en-US', { day: '2-digit', timeZone:'America/chicago' }).format;
            const monthData = monthValue(data);
            const dateData = dateValue(data);
            const timeFromData =dayjs.utc(response.data.upcomingAppointmentDateTime.substring(0, 23))
              .tz('America/chicago').format('hh:mm A');
            const timeToData = toDate.toLocaleTimeString('en-US', {
              hour12: true,
              hour: 'numeric',
              minute: 'numeric',
            });
            const dateString = response?.data.upcomingAppointmentDateTime?.substring(0, 16).split('T')[0];
            if (new Date(data) >= new Date() && 
            (response?.data?.status === 'Confirmed' || response?.data?.status === 'Scheduled')) {
              const date1 = new Date(dateString).setHours(0,0,0,0);
              const date2 = new Date().setHours(0,0,0,0);

              if(date1 === date2){
                setShowCheckIn(true);
              }else {
                setShowCheckIn(false);
              }
              setStatus('confirmed');
              sessionStorage.setItem('appointmentDate', dateString);
            } else if(new Date(data) <= new Date()){
              setStatus('datepassed');
            } else if(drChronoStatus === 'No-Show' || drChronoStatus === 'Cancelled' || drChronoStatus === 'Complete'){
              setStatus('noAppointment'); 
            } else if(drChronoStatus === 'Late Cancel'){
              setStatus('lateCancel');
            }
            else {
              setStatus('pending');
            }
            setAppointmentDetails({
              date: dateData,
              month: monthData,
              year: data.getFullYear(),
              timeFrom: timeFromData,
              timeTo: timeToData,
              upcomingAppointmentDateTime:response?.data?.upcomingAppointmentDateTime,
              providerName:response?.data?.providerName,
              evaluation:response?.data?.evaluation,
              consultationType:response?.data?.consultationType,
              virtualMeetingLink:response?.data?.virtualMeetingLink,
              officeAddress: response?.data?.officeAddress,
              checkIn : response?.data?.checkin === 'physical_checkin_completed' ? true :false
            });
            setCheckIn(response?.data?.checkin === 'physical_checkin_completed' ? true :false);
          } else {
            dispatch(getScheduleAppointmentDetails(user?.patientId))
              .unwrap()
              .then((response: any) => {                
                if (Object.keys(response?.data).length > 0) {
                  setStatus('pending');
                  sessionStorage.setItem('appointmentDate', 'pending');
                } else {
                  setStatus('new');
                }
              });
          }
        }
      });
    if(user.patientId)
      dispatch(getVitalsDetails(user.patientId))
        .unwrap()
        .then((response: any) => {
          if (response?.status === 200) {
            setSystemVitals(response.data.newAppointment);
          } 
        });

    dispatch(getWellbeingStatus(user?.patientId))
      .unwrap()
      .then((response: any) => {
        if (response?.status === 200) {
          setWellbeingStatus(response?.data);
          setUpcomingAppointmentPreparation(response?.data?.upcomingAppointmentPreparation);
        }
      });   
    //eslint-disable-next-line
  }, [user?.patientId]);

  useEffect(() =>{
    let intervalId:any;
    if(undoTime >= 0){
      intervalId = setInterval(() => {      
        setUndoTime(undoTime - 1);      
      }, 1000);
    } else{
      setHideBorder(false);
    }
    return() => clearInterval(intervalId);
  }, [undoTime]);


  const MS = 60000;
  useEffect(() => {
    checkTime();
    if(new Date(appointmentDetails?.upcomingAppointmentDateTime).getDate() === new Date().getDate() 
      && disableJoinNowButton){
      const interval = setInterval(() => {
        console.log('0');
        checkTime();
      }, MS);    
      return () => clearInterval(interval); 
    }
  });

  const checkTime =()=>{
    const currentTime = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit'});
    const ft = dayjs(`2000-01-01 ${appointmentDetails?.timeFrom?.substring(-2)}`);
    const tt = dayjs(`2000-01-01 ${currentTime.substring(-2)}`);
    const mins = ft.diff(tt, 'minutes', true);
    if(mins < 16){
      setDisableJoinNowButton(false);
    }
  };

  const getNextPatientLink=()=>{
    if(preferredLocation === '1315 Macom Dr, Suite 207, Naperville, IL - 60564'){
      setTimeout(() => {
        window.open('https://nextpatient.co/p/mindweal-naperville/schedule', '_blank');
      });
    }
    else if(preferredLocation === '19 E First Street, Hinsdale, IL - 60521'){
      setTimeout(() => {
        window.open('https://nextpatient.co/p/mindweal-hinsdale/schedule', '_blank');
      });
    }
    // eslint-disable-next-line max-len
    else if(preferredLocation === '1034 S Brentwood Blvd, Suite 555, St. Louis, MO 63117'){
      setTimeout(() => {
        window.open('https://nextpatient.co/p/mindweal-richmondheights/schedule', '_blank');
      });
    }
    else if(preferredLocation === '3412 Office Park Drive, Marion, IL - 62959'){
      setTimeout(() => {
        window.open('https://nextpatient.co/p/mindweal-marion/schedule', '_blank');
      });
    }
    else {
      setTimeout(() => {
        window.open('https://nextpatient.co/p/mindweal-health/schedule', '_blank');
      });
    }
  };
  
  const handleMeetingSetup = () => {
    if (props.profileStatus === 'COMPLETED') {
      setShowLoadingMessage(true);
      setDisableMeetingButton(true);
      dispatch(getDrChronoChartDetails(user?.patientId))
        .unwrap()
        .then((res: any) => {
          if(res?.status === 200){
            if (res?.data?.chartId === 'Not Available') {
              setShowLoadingMessage(false);
              setShowDelayMessage(true);
              
              dispatch(createPatient(user?.patientId))
                .unwrap()
                .then((response:any) => {
                  if(response?.status === 200){
                    setShowDelayMessage(true);
                    setShowLoadingMessage(false);
                    if(showDelayMessage === true || response?.data === 'OK'){
                      const interval = setInterval(()=>{
                        dispatch(getDrChronoChartDetails(user?.patientId))
                          .unwrap()
                          .then((res:any) =>{
                            if(res?.status === 200 && res?.data?.chartId !== 'Not Available'){ 
                              setShowDelayMessage(false);
                              getNextPatientLink();
                              clearInterval(interval);                              
                            }
                          });
                      }, 30000);
                    }
                  }
                });
            } else if (res?.data?.chartId !== 'Not Available' ) {
              setShowLoadingMessage(false);
              setShowDelayMessage(false);
              getNextPatientLink();
            }
            
          }
          else{
            setShowSyncError(true);
            setShowLoadingMessage(false);
            setDisableMeetingButton(false);
          }
        })
        .catch(() =>{
          setDisableMeetingButton(false);
          setShowSyncError(true);
          setShowLoadingMessage(false);
        }); 
    } else {
      setDisplayModal(true);
    }
  };

  const getConsultaionType = (appointmentDetails : any) => {
    return (<>
      <Grid item xs={12}>
        <Typography variant='h2' fontSize={18}>
          {appointmentDetails?.evaluation}{appointmentDetails?.consultationType !== null ? ', ' :''} 
          {appointmentDetails?.consultationType}
        </Typography>
      </Grid>
      {appointmentDetails?.consultationType === 'In - Person' ? (
        <Grid item xs={12}>
          
          <Typography variant='body1'>{appointmentDetails?.officeAddress}</Typography>
        </Grid>
      ) : null} 
    </>);
  };
  
  const handleWellbeing = () => {
    navigate(`${ROUTE.WELLBEING}`, { state: { wellbeingStatus: wellbeingStatus } });    
  };

  const getAppointmentStatusDetails=()=>{
    return(
      <Accordion
        defaultExpanded={true}
        expanded={expanded}
        sx={{ border: '1px solid #FFC44D', 
          borderRadius: '12px!important', 
          background:'#FFFBF1',
          minWidth:'100%',
        }}
      >
        <AccordionSummary 
          expandIcon={ expanded ? <RemoveIcon sx={{color:'#3467FF'}}/> : <AddIcon sx={{color:'#3467FF'}}/>}
          onClick={() => setExpanded(!expanded)}
          sx={{
            '&.Mui-expanded': {
              minHeight: '48px!important',
            },
            background: '#FFFBF1!important',
          }}> 
          <Typography sx={{            
            fontSize: 12, 
            fontWeight: '500', 
            lineHeight:'18px', 
            background: appointmentStatusDetails[appointmentStatus]?.background,
            padding:'2px 12px',
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            borderRadius:'12px',
            color:'#FFF',
          }}>
            {appointmentStatusDetails[appointmentStatus]?.status}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{ '&.MuiAccordionDetails-root': { padding: '0px 16px' } }}
        >

          <Typography  sx={{ fontSize: '14px', fontWeight: '400', lineHeight: '20px', color:'#1C1F24' }}>
            {appointmentStatusDetails[appointmentStatus]?.instruction}
          </Typography>
        </AccordionDetails>
                      
      </Accordion>
    );

  };

  const handleCheckIn=()=>{
    const data = {
      patientId: user?.patientId,
      status: 'physical_checkin_completed'
    };
    setCheckIn(true);
    setHideBorder(true);    
    setUndoTime(60);
    dispatch(getCheckInDetails(data))
      .unwrap()
      .then((response:any) =>{
        if(response?.status === 200){
          setCheckIn(true);
          setHideBorder(true);    
          setUndoTime(60);
        }
      });
  };

  const handleUndo=()=>{
    const data = {
      patientId: user?.patientId,
      status: 'physical_checkin_cancelled'
    };
    setCheckIn(false);
    setHideBorder(false);    
    setUndoTime(0);

    dispatch(getCheckInDetails(data))
      .unwrap()
      .then((response:any) =>{
        if(response?.status === 200){
          setCheckIn(false);
          setHideBorder(false);    
          setUndoTime(0);
        }
      });
  };

  const handleJoinNow=()=>{
    setTimeout(() => {
      window.open(appointmentDetails?.virtualMeetingLink, '_blank');
    });
  };

  const handleAddCreditCard=()=>{
    dispatch(getAddCardLink({}))
      .unwrap()
      .then((res:any) => {
        if(res?.status === 200){
          setTimeout(() => {
            window.open(res?.data?.url, '_blank');
          });
        }
      });
  };



  const handlePay=()=>{
    setDisablePayButton(true);
    dispatch(payAmount(user?.patientId))
      .unwrap()
      .then((response:any) =>{
        if(response?.status === 200){
          if(response?.data?.status === 'SUCCESS'){
            setShowPaymentSuccessMessage(true);
          }else{
            toast('Payment attempt was unsuccessful.', {
              type:'error',
              icon:true
            });
            setDisablePayButton(false);
          } 
        }
      });
  };

  return status === '' ? (
    <CircularProgress />
  ) : (
    <>
      { appointmentCharge !== null && appointmentCharge.creditCardOnFile === false ?
        <StyleCard>
          <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Grid container py={0}>
              <Grid item xs={12} display={'flex'} flexDirection={'column'}>
                <Typography variant='h2'>Update payment details</Typography>
                <Typography>To maintain your physician-patient relationship with us, we require a valid <b>credit or
                 debit card </b> on file. Co-pays will be charged automatically on the day of your. </Typography>
              </Grid>
              <Grid container xs={12} display={'flex'} flexDirection={'row'} 
                sx={{ background: '#f8f5ff', border: '1px solid #EAECF0', borderRadius: '8px', p:'12px' }}>
                <Grid item xs={1} md={0.5}>
                  <CardMedia component={'img'} src='images/lock.png' alt='chat-logo'
                    sx={{ width: '24px', height: '24px' }} />
                </Grid>
                <Grid item xs={11} md={11.5}>
                  <Typography>Your card information is securely managed by our PCI-certified payment processor 
                and is not stored on MindWeal.</Typography>
                </Grid>
              </Grid>
              <Grid mt='16px'>
                <Button onClick={() => handleAddCreditCard()}>Add Credit Card</Button>
              </Grid>
            </Grid>
          </CardContent> 
        </StyleCard>
        :null }

      { appointmentCharge?.amountDue && appointmentCharge?.amountDue !== 0 && appointmentCharge !== null && 
      appointmentCharge.creditCardOnFile !== false && appointmentCharge.cardExpired === false 
      && !showPaymentSuccessMessage ?
        <StyleCard>
          <CardContent 
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#FFECC8', 
              border:'1px solid #FFC44D', borderRadius:'12px' }}>
            <Grid container py={0}>
              <Grid item xs={12} display={'flex'} flexDirection={'column'}>
                <Typography variant='h2'>Pay for Your Appointment</Typography>
                <Typography> Full payment for services is due 4 days prior to your appointment. 
                  If payment is not received by this time, the system will automatically charge your card. 
                  For cancellations made at least 1 day before your appointment (excluding weekends and holidays),
                  a full refund will be issued. For cancellations within 24 hours of the appointment or
                  missed appointments (excluding weekends and holidays), a refund will be issued,
                  minus a $50 missed appointment fee.</Typography>
              </Grid>
              <Grid item xs={12}
                sx={{display:'flex', justifyContent:'left', alignItems:'center'}}> 
                <Button onClick={handlePay} disabled={disablePayButton}>Pay ${appointmentCharge?.amountDue}</Button>
              </Grid>
              
            </Grid>
          </CardContent> 
        </StyleCard>
        : null }
      {showPaymentSuccessMessage && 
         <StyleCard>
           <CardContent 
             sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#F6FEF9', 
               border:'1px solid #039855', borderRadius:'12px' }}>
             <Grid container py={0}>
               <Grid item xs={12} display={'flex'} flexDirection={'column'}>
                 <Typography variant='h2'>Payment Successful!</Typography>
                 <Typography> Your payment has been processed successfully. 
                  If you need to cancel your appointment, please do so at least 1 day in advance 
                  (excluding weekends and holidays) to receive a full refund. For cancellations 
                  within 24 hours of the appointment or missed appointments (excluding weekends and holidays),
                  a refund will be issued minus a $50 missed appointment fee.</Typography>
               </Grid>           
             </Grid>
           </CardContent> 
         </StyleCard>
      }

      { appointmentCharge !== null && appointmentCharge.cardExpired === true ?
        <StyleCard>
          <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Grid container py={0}>
              <Grid item xs={12} display={'flex'} flexDirection={'column'}>
                <Typography variant='h2'>Payment Card Expired!</Typography>
                <Typography color='#B42318' sx={{fontWeight:700}}>Please update your credit/debit card details 
                  to continue uninterrupted service</Typography>
                <Typography>
                To maintain your physician-patient relationship with us, we require a valid credit or debit card on 
                file. Co-pays will be charged automatically on the day of your appointment after it is completed. 
                For out-of-pocket visits, full payment according to our fee schedule will be processed before your 
                scheduled visit.  
                </Typography>
              </Grid>
              <Grid container xs={12} display={'flex'} flexDirection={'row'} 
                sx={{ background: '#f8f5ff', border: '1px solid #EAECF0', borderRadius: '8px', p:'12px' }}>
                <Grid item xs={0.5}>
                  <CardMedia component={'img'} src='images/lock.png' alt='chat-logo'
                    sx={{ width: '24px', height: '24px' }} />
                </Grid>
                <Grid item xs={11.5} >
                  <Typography>Your card information is securely managed by our PCI-certified payment processor 
                    and is not stored on MindWeal.</Typography>
                </Grid>
              </Grid>
              <Grid mt='16px'>
                <Button onClick={() => handleAddCreditCard()}>UPDATE CREDIT CARD</Button>
              </Grid>
            </Grid>
          </CardContent> 
        </StyleCard>
        :null }  
      

      {showCheckIn && (drChronoStatus === 'Confirmed' || drChronoStatus === 'Scheduled') ?
        <>
          <Grid container
            sx={{margin:'12px 0px 0px 16px', 
              padding:'16px 20px',
              maxWidth: {md :'820px', xs:'360px'},
              background:'#FFECC8',
              border:'1px solid #FFC44D', 
              borderRadius:'12px',
              borderBottomLeftRadius:hideBorder ? '0px' : '12px',
              borderBottomRightRadius:hideBorder ? '0px' : '12px',
              borderBottom: hideBorder ? 'none' : '1px solid #FFC44D'
            }}
            alignItems='center'
          >                
            <Grid item xs={12} md={9} >
              <Typography variant='h2'>
                {appointmentDetails?.consultationType === 'In - Person' && checkIn ?
                  'Thank you for checking in!' :
                  'You have an appointment Today!' }</Typography>
              {appointmentDetails?.consultationType === 'Virtual Meeting' ? 
                <Typography variant='body1' mt={1}>
                 The <b>&apos;Join Now&apos;</b> button activates 15 min before your virtual visit.
                </Typography>
                :
                appointmentDetails?.consultationType === 'In - Person' && checkIn ? 
                  <Typography variant='body1' mt={1}>Please have a seat. 
                  Your provider will be with you shortly.</Typography> 
                  :
                  <Typography variant='body1' mt={1}>Tap <b>&apos;Check-In&apos;</b>
              &nbsp;once you&apos;re in the office waiting room.</Typography> 
              }
            
            </Grid>
            <Grid item xs={12} md={3} sx={{marginTop:{xs:'10px' }}}>
              {appointmentDetails?.consultationType === 'Virtual Meeting' ?
                <Button size='small' onClick={handleJoinNow} disabled={disableJoinNowButton}> Join Now</Button>
                :
                <Button size='small' onClick={handleCheckIn} disabled={checkIn}>
                  {checkIn ? 'Checked In' : 'Check In'}</Button>
              }
            </Grid>          
          </Grid>
          { checkIn && undoTime >= 0 ?
            <Grid container 
              sx={{margin:'0px 0px 0px 16px ', 
                padding:'16px 20px',
                maxWidth: {md :'820px', xs:'360px'},
                background:'#FFFAF0',
                border:'1px solid #FFC44D', 
                borderTop:'none',
                borderRadius:'12px',
                borderTopLeftRadius:'0px',
                borderTopRightRadius:'0px'
              }}>
              <Typography variant='body1'> Checked in mistakenly,</Typography> 
              <Button variant='text' onClick={handleUndo} sx={{padding:'0px', mt:'-3px'}}> 
                Undo</Button> - 00:{undoTime}
            </Grid> : ''}
        </>
        : ''}
      <>
        {showLoadingMessage ?
          <Box sx={{background:'#FFC44D', p:'5px', 
            position:'absolute', zIndex:9999,
            borderRadius:'8px',
            marginTop:'0', marginLeft:{md:'13%', xs:'6%'}, width:{md:'500px', xs:'100%'}}}>
            <CircularProgress size={'18px'} sx={{mr:'8px'}}/>
               We are syncing your information, you can book with us in a moment
          </Box>
          : showDelayMessage ? 
            <Box sx={{background:'#FFC44D', p:'5px', 
              position:'absolute', zIndex:9999,
              borderRadius:'8px',
              marginTop:'0', marginLeft:{md:'20%', xs:'6%'}, width: '300px'
            }}>
              <CircularProgress size={'18px'} sx={{mr:'8px'}}/>This is taking a while
            </Box>
            : ''}
        {showSyncError ?
          <Alert  
            onClose={() => setShowSyncError(false)}
            icon={<SyncProblemIcon fontSize='small' color='error'/>}
            sx={{background:'#FDF1F1', border:'1px solid #FFC8C9', 
              position:'absolute', zIndex:9999,
              marginTop:'0', marginLeft:{md:'16%', xs:'6%'},width:{md:'400px', xs:'80%'}}}>
            Server sync-up failed, try scheduling manually </Alert>
          : ''}

        <StyleCard>
          <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Grid container py={0}>
              <Grid item xs={12} md={8} display={'flex'} flexDirection={'column'}>
                {status === 'pending' ? (
                  <><Stack direction='row' spacing={1} alignItems={'center'}>
                    <Typography variant='h2'>Schedule Clinical Evaluation</Typography>
                    <StyledTooltip
                      title={'Contact front desk at 618-310-0085 for more info'}
                      arrow
                      placement='top'
                      enterTouchDelay={0}
                      enterDelay={0}
                      leaveDelay={500}
                    >
                      <InfoOutlinedIcon
                        fontSize='small'
                        sx={{ color: COLORS.Link, marginLeft: '10px', marginBottom: '8px!important' }}
                      />
                    </StyledTooltip>
                  </Stack>
                  <Typography variant='body1'>
                    We have received your request. 
                  </Typography>
                  <Typography variant='body1'>We will call you shortly to schedule your visit.</Typography>
                  
                  </>
                ) : status === 'confirmed' ? (
               
                  <Stack spacing={1} direction={'column'}>
                    <Stack direction='row' spacing={1} alignItems={'center'}>
                      <Typography variant='subtitle1'>Upcoming Appointment</Typography>
                      <StyledTooltip
                        title={'Contact front desk at 618-310-0085 for more info'}
                        arrow
                        placement='top'
                        enterTouchDelay={0}
                        enterDelay={0}
                        leaveDelay={500}
                      >
                        <InfoOutlinedIcon
                          fontSize='small'
                          sx={{ color: COLORS.Link, marginLeft: '10px', marginBottom: '8px!important' }}
                        />
                      </StyledTooltip>
                    </Stack>
                    <Stack direction='row' spacing={1} alignItems={'center'}>
                      <CardMedia
                        component={'img'}
                        src='images/calendar-check.svg'
                        alt='card-img1'
                        sx={{ height: '20px', width: '20px', mb: '8px' }}
                      />
                      <Typography variant='h2' fontSize={18}>
                        {appointmentDetails.month} {appointmentDetails.date}, {appointmentDetails.year} at{' '}
                        {appointmentDetails.timeFrom}
                      </Typography>
                    </Stack>
                    <Stack direction='row' spacing={1} alignItems={'center'}>
                      <CardMedia
                        component={'img'}
                        src='images/traced.svg'
                        alt='card-img1'
                        sx={{ height: '20px', width: '20px', mb: '8px' }}
                      />
                      <Typography variant='h2' fontSize={18}>
                      Provider - {appointmentDetails?.providerName}
                      </Typography>
                    </Stack>
                    <Stack direction='row' spacing={1} alignItems={'flex-start'}>
                      <CardMedia
                        component={'img'}
                        src='images/marker-pin.svg'
                        alt='card-img1'
                        sx={{ height: '20px', width: '20px', mb: '8px' }}
                      />
                      <Grid container>
                        {getConsultaionType(appointmentDetails)}
                      </Grid>
                    </Stack>                  
                  </Stack>
               
                )  :
                  status === 'datepassed' || status === 'noAppointment' || status === 'lateCancel' ? (
                    <>
                
                      <Typography variant='h2'>No Upcoming Appointments!</Typography>
                      <Typography variant='body1'>Schedule an appointment now. 
                        Can’t find your preferred date and time? Call 618-310-0085 for assistance.</Typography>
                    </>
                  ): (
                    <>
                      <Typography variant='h2'>Clinical Visit</Typography>
                      {showSyncError ?
                        <Typography variant='body1'>
                     Oops something went wrong! Please call us at <b>(618)310-0085</b> to schedule.
                        </Typography> :
                        <>
                          <Typography variant='body1'>
                     Meet our board-certified pediatric psychiatry expert for personalized care.
                          </Typography>
                          <Typography variant='body1'>
                          Schedule an appointment now. Can’t find your preferred date and time? 
                          Call 618-310-0085 for assistance.
                          </Typography>
                        </>
                      }
                      {showSyncError ?
                        <Button variant='text' size='small' 
                          onClick={handleMeetingSetup} 
                          style={{ cursor: 'pointer', width:'fit-content' }}
                          disabled={disableMeetingButton}>
                      Try Again
                        </Button>
                        :
                        <Button size='small' 
                          onClick={handleMeetingSetup} 
                          style={{ cursor: 'pointer', width:'fit-content' }}
                          disabled={disableMeetingButton}>
                    Set Up Appointment
                        </Button>
                      }
                    </>
                  )}
              </Grid>          
              <Grid item xs={12} md={4} display={'flex'} alignItems={'center'}>
                {status === 'datepassed' || status === 'noAppointment' || status === 'lateCancel'? (
                  <Button onClick={handleMeetingSetup} style={{ cursor: 'pointer' }} sx={{ mt: 2 }}>
                    SCHEDULE NOW
                  </Button>
                
                ) : (
                  <CardMedia
                    component={'img'}
                    src='images/appointmentConfirmed.png'
                    alt='card-img1'
                    sx={{ objectFit: 'contain', ml: 'auto', width: 'auto', height: '129px' }}
                  />
                )}
              </Grid>
            </Grid>          
          </CardContent>
          <Box margin={2}>
            {(new Date(appointmentDetails?.upcomingAppointmentDateTime).getDate() === new Date().getDate() &&
                (status === 'confirmed' || status === 'new')) 
              ?
              getAppointmentStatusDetails() :
              ''}
          </Box>
        </StyleCard>
      </>
      { status !== 'confirmed' && status !== 'new' ?
        <Grid container>
          <Grid item xs={12}>
            <StyleCard>
              <CardContent>
                <CardMedia
                  component={'img'}
                  src='images/spiral.svg'
                  alt='card-img'
                  sx={{ width: 228, height: 'auto', position: 'absolute', 
                    top: 0, margin: 'auto', left: 0, right: 0 }}
                />
                <Grid container spacing={1}>
                  <Grid item xs={12} md={8}>
                    <Typography variant='h2'>Previous Appointment Status</Typography>
                  </Grid>
                  {getAppointmentStatusDetails() }
                </Grid>
              </CardContent>
            </StyleCard>
          </Grid>
        </Grid> : ''}
      <Grid container>
        {upcomingAppointmentPreparation && (status !== 'datepassed' && status !== 'noAppointment') ? 
          <Grid item xs={12}>
            <StyleCard>
              <CardContent>
                <CardMedia
                  component={'img'}
                  src='images/spiral.svg'
                  alt='card-img'
                  sx={{ width: 228, height: 'auto', position: 'absolute', top: 0, margin: 'auto', left: 0, right: 0 }}
                />
                <Grid container spacing={1}>
                  <Grid item xs={12} md={8}>
                    <Typography variant='h2'>Upcoming Appointment Preparation</Typography>
                    <Typography variant='body1'>
                        Take our survey to gauge the mental wellness of your child.
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end', justifyContent: 'center' }}
                  >
                    <Button variant='contained' onClick={handleWellbeing}>Start Survey</Button>
                  </Grid>
                </Grid>
              </CardContent>
            </StyleCard>
          </Grid> : null}
        {systemVitals && (status !== 'datepassed' && status !== 'noAppointment') ?<Grid item xs={12}>
          <StyleCard>
            <CardContent>
              <CardMedia
                component={'img'}
                src='images/solid-spiral.svg'
                alt='card-img'
                sx={{ width: 228, height: 'auto', position: 'absolute', top: 0, margin: 'auto', left: 0, right: 0 }}
              />
              <Grid container spacing={1}>
                <Grid item xs={12} md={8}>
                  <Typography variant='h2'>Upcoming Appointment Preparation</Typography>
                  <Typography variant='body1'>Please share your vital signs before your appointment.</Typography>
                  <Button variant='contained' 
                    onClick={() => navigate(ROUTE.SYSTEM_VITALS, {state:{validRoute:true}})}>
                      Enter Vital Signs
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end', justifyContent: 'center' }}
                >
                  <CardMedia
                    component={'img'}
                    src='images/vitals.svg'
                    alt='card-img1'
                    height='119px'
                    sx={{ objectFit: 'contain', ml: 'auto', width: 'auto' }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </StyleCard>
        </Grid>:null}
      </Grid>
      
      <Dialog open={displayModal} fullWidth={false}>
        <DialogContent>
          <Typography variant='h3' textAlign={'center'}>
            Profile completion
          </Typography>
          <Box m={1}>
            <Typography mt={2}>Kindly fill out your profile details before setting up your appointment.</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button sx={{ marginTop: 4 }} onClick={() => navigate(ROUTE.PROFILE)}>
              Okay
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AppointmentStatus;
